import { selectors as authSelectors } from 'App/ducks/authDuck';
import { selectors as userSelectors } from 'App/ducks/userDuck';
import { createTask, fetchTasksAssignedToUser, getTask, updateTask, deleteTask } from '../api/taskManagerApi';
import { creators } from '../ducks/tasksDuck';

export const fetchTasksAction = (organizationShortName, userId) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  dispatch(creators.fetch());
  try {
    const data = await fetchTasksAssignedToUser(token, organizationShortName, userId, 0, 100);
    dispatch(creators.fetchSuccess(data.entries));
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};

export const fetchTaskAction = (organizationShortName, taskId) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  try {
    const data = await getTask(token, organizationShortName, taskId);
    return data;
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};

export const createTaskAction = (
  organizationShortName,
  title,
  description,
  labels,
  actions = [],
  assignedUser,
  resources = []
) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const user = userSelectors.user(state);
  try {
    const task = await createTask(token, organizationShortName, {
      title,
      description,
      labels,
      assignedUser: assignedUser || user.userId,
      actions: actions.map(action => ({ description: action })),
      resources: resources.map(resource => ({ resource })),
    });
    dispatch(fetchTasksAction(organizationShortName));
    return task;
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};

export const deleteTaskAction = (organizationShortName, taskId) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  try {
    await deleteTask(token, organizationShortName, taskId);
    return dispatch(fetchTasksAction(organizationShortName));
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};

export const updateTaskAction = (organizationShortName, taskId, task) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  try {
    await updateTask(token, organizationShortName, taskId, task);
    return dispatch(fetchTasksAction(organizationShortName));
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};
