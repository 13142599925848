import { creators } from '../ducks/featureDuck';
import { selectors as authSelectors } from '../ducks/authDuck';
import { selectors as userSelectors } from '../ducks/userDuck';
import { featuresV2 as fetchFeaturesV2 } from '../api/featuresApi';

export const featuresAction = () => async (dispatch, getState) => {
  const state = getState();
  const organizationShortName = userSelectors.organizationShortName(state);
  const token = authSelectors.token(state);
  dispatch(creators.fetch());
  try {
    const features = await fetchFeaturesV2(token, organizationShortName);
    dispatch(creators.fetchSuccess(features));
  } catch (error) {
    dispatch(creators.fetchFailed(error));
  }
};
