import React, { useMemo } from 'react';
import useDateFormatter from './useDateFormatter';
import { VARIANTS } from './useDateFormatter';

const DateFormatter = ({ date, sourceTimeZone, destinationTimeZone, variant, format, ...props }) => {
  const dateFormatter = useDateFormatter();
  const dateString = useMemo(() => {
    if (variant === 'iso') {
      return dateFormatter.iso(date, sourceTimeZone, destinationTimeZone);
    }
    return dateFormatter.format(date, sourceTimeZone, destinationTimeZone)(format || VARIANTS[variant]);
  }, [dateFormatter, date, sourceTimeZone, destinationTimeZone, variant, format]);
  return <span {...props}>{dateString}</span>;
};

export default DateFormatter;
