import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import InboxIcon from '@mui/icons-material/Inbox';

const EmptyListItem = () => {
  return (
    <ListItem sx={{ flexDirection: 'column' }}>
      <div>
        <InboxIcon />
      </div>
      <div>
        <FormattedMessage id="Tasks-TasksList-emptyList-title" />
      </div>
    </ListItem>
  );
};

export default EmptyListItem;
