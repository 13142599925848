import { creators as userCreators } from '../ducks/userDuck';
import { creators as organizationCreators } from '../ducks/organizationDuck';
import { tokenDuck } from '../../store/jwtMiddleware';
import { getConfigurationAction } from './configuration';

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

export const mapRolesAndOrganizations = access_token => {
  const accessTokenParsed = JSON.parse(atob(access_token.split('.')[1]));
  const roles = accessTokenParsed.sws_permissions.filter(p => p.startsWith('connect.'));
  return roles.map(roleString => {
    const [, organization, role] = roleString.split('.');
    return {
      role: capitalize(role),
      organizationId: organization,
      organizationShortName: organization,
    };
  });
};

const getRoles = (profile, access_token) => {
  const rolesAndOrganizations = mapRolesAndOrganizations(access_token);
  return rolesAndOrganizations.map(rog => ({
    role: rog.role,
    userId: profile.sub,
    organizationId: rog.organizationId,
    organizationDetails: {
      name: rog.organizationShortName,
      shortName: rog.organizationShortName,
    },
  }));
};

const mapUser = ({ profile, access_token }) => {
  const roles = getRoles(profile, access_token);
  return {
    userId: profile.sub,
    email: profile.email,
    name: profile.name,
    organizationId: roles[0].organizationId,
    roles: roles,
  };
};

const mapOrganization = user => {
  return {
    organizationId: user.roles[0].organizationId,
    organizationName: user.roles[0].organizationDetails.name,
    organizationShortName: user.roles[0].organizationDetails.shortName,
    managementApiEnabled: true,
  };
};

export const initializeFoundation = userInfo => async (dispatch, getState) => {
  const user = mapUser(userInfo);
  const organization = mapOrganization(user);
  dispatch(userCreators.fetchSuccess(user));
  dispatch(organizationCreators.fetchSuccess(organization));
  await dispatch(getConfigurationAction());
  dispatch(
    tokenDuck.creators.setToken(userInfo.access_token, 'refreshToken', new Date(userInfo.expires_at * 1000).toString())
  );
};
