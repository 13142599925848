import { useState, useEffect, useCallback } from 'react';
import en from './languages/en.json';

export const localeModules = {
  no: () => import(/* webpackChunkName: "Locale-NO" */ './languages/no.json'),
  zh: () => import(/* webpackChunkName: "Locale-ZH" */ './languages/zh.json'),
  fr: () => import(/* webpackChunkName: "Locale-FR" */ './languages/fr.json'),
  dk: () => import(/* webpackChunkName: "Locale-DK" */ './languages/dk.json'),
};

export const orgLocaleModules = {
  greenbird: () => import(/* webpackChunkName: "Locale-Greenbird" */ './languages/greenbird.json'),
};

const emptyLocale = () => Promise.resolve({ default: {} });

const useLocaleMessages = (locale, organizationShortName) => {
  const [localeMessages, setLocaleMessages] = useState(null);
  const [error, setError] = useState(false);
  const loadLocale = useCallback(async () => {
    try {
      const langLocale = await (localeModules[locale] || emptyLocale)();
      const orgLocale = await (orgLocaleModules[organizationShortName] || emptyLocale)();
      setLocaleMessages({ ...en, ...langLocale.default, ...orgLocale.default });
    } catch (e) {
      setError(true);
    }
  }, [locale, organizationShortName, setLocaleMessages]);
  useEffect(() => {
    loadLocale();
  }, [loadLocale]);
  return [localeMessages, error];
};

export default useLocaleMessages;
