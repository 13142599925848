import React, { Fragment, useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const styleSx = {
  info: {
    color: 'info.main',
  },
  success: {
    color: 'primary.dark',
  },
  warning: {
    color: 'warning.main',
  },
  danger: {
    color: 'error.main',
  },
  error: {
    color: 'error.main',
  },
};

const ConfirmDialog = ({
  title,
  confirmText,
  contentText,
  content,
  cancelText,
  children,
  variant = 'danger',
  maxWidth,
  onConfirm,
  onCancel,
  fullWidth,
  disabled,
  labels = {},
}) => {
  const [open, setOpen] = useState(false);
  const onToggle = useCallback(
    isConfirmed => {
      setOpen(prev => !prev);
      if (isConfirmed === true) {
        onConfirm();
      } else if (onCancel && !open) {
        onCancel();
      }
    },
    [onCancel, onConfirm, open]
  );
  const clickHandlerChild = React.cloneElement(children, { onClick: onToggle });
  return (
    <Fragment>
      {clickHandlerChild}
      <Dialog maxWidth={maxWidth} fullWidth={fullWidth} open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={title || labels.title || 'Confirm deletion'} />
        <DialogContent>
          {contentText && <DialogContentText children={contentText} />}
          {content}
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            onClick={() => onToggle(false)}
            children={cancelText || labels.cancelText || 'Cancel'}
          />
          <Button disabled={disabled} onClick={() => onToggle(true)} sx={styleSx[variant]}>
            {confirmText || labels.confirmText || 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ConfirmDialog;
