export const typography = {
  h1: {
    fontSize: '4rem',
    lineHeight: 'auto',
  },
  h2: {
    fontSize: '3rem',
    lineHeight: 'auto',
  },
  h3: {
    fontSize: '2.25rem',
    lineHeight: '48px',
  },
  h4: {
    fontSize: '1.75rem',
    lineHeight: '36px',
  },
  h5: {
    fontSize: '1.5rem',
    lineHeight: '32px',
  },
  h6: {
    fontSize: '1.25rem',
    lineHeight: '28px',
  },
  subtitle1: {
    fontSize: '1.125rem',
    lineHeight: '24px',
  },
  subtitle2: {
    fontSize: '.875rem',
    lineHeight: '24px',
  },
  body1: {
    fontSize: '1.125rem',
    lineHeight: '28px',
  },
  body2: {
    fontSize: '1rem',
    lineHeight: '24px',
  },
};
