import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import darkPalette from './theme/paletteDark';
import lightPalette from './theme/paletteLight';
import geTheme from './theme/geTheme';
import { selectors as configurationSelectors } from 'App/ducks/configurationDuck';

const THEME_MODE_KEY = 'GRIDOS:CONNECT:themeMode';

export const useDarkMode = () => {
  const configuration = useSelector(state => configurationSelectors.configuration(state), shallowEqual);
  const mode = configuration.theme;
  useEffect(() => {
    localStorage.setItem(THEME_MODE_KEY, mode || 'dark');
  }, [mode]);
  return useMemo(() => {
    if (!mode) {
      return true;
    }
    if (mode === 'auto') {
      const dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
      return dark;
    }
    return mode === 'light' ? false : true;
  }, [mode]);
};

const ThemeProvider = ({ children, theme }) => {
  const darkMode = useDarkMode();
  const currentTheme = useMemo(() => {
    if (theme) {
      return theme;
    }
    return darkMode ? geTheme(darkPalette) : geTheme(lightPalette);
  }, [darkMode, theme]);
  return (
    <MuiThemeProvider theme={currentTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
