import React from 'react';
import Box from '@mui/material/Box';
import Background from '../Background';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GE_THEME } from 'config/features';

const wrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '100vh',
  overflow: 'auto',
  backgroundImage: `url(${Background})`,
};

const PublicPageWrapper = ({ children }) => (
  <Box sx={wrapperSx} component="main">
    {children}
    <Box sx={{ flexGrow: 1 }}></Box>
    <Typography
      sx={{
        p: 0.5,
        display: 'block',
        alignSelf: 'center',
        textAlign: 'center',
        bgcolor: 'background.primary',
        my: 'auto',
        fontSize: 12,
        width: '50%',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        overflow: 'hidden',
        opacity: 0.8,
      }}
      variant="body1"
    >
      {GE_THEME && <FormattedMessage id="general.greenbird" />}
    </Typography>
  </Box>
);

export default PublicPageWrapper;
