import { connect } from 'react-redux';

import Tasks from './Tasks';
import { fetchTasksAction, fetchTaskAction } from '../../actions/tasks';
import { fetchUsersAction } from '../../actions/users';
import { selectors, creators } from '../../ducks/tasksDuck';
import { selectors as usersSelectors } from '../../ducks/usersDuck';
import { selectors as userSelectors } from 'App/ducks/userDuck';

const mapStateToProps = state => ({
  isLoading: selectors.fetchLoading(state),
  tasks: selectors.data(state),
  showTasksList: selectors.showTasksList(state),
  showTaskId: selectors.showTaskId(state),
  user: userSelectors.user(state),
  users: usersSelectors.data(state),
  organizationShortName: userSelectors.organizationShortName(state),
});

const mapDispatchToProps = {
  fetchTasks: fetchTasksAction,
  setShowTasksList: creators.setShowTasksList,
  setShowTaskId: creators.setShowTaskId,
  fetchTask: fetchTaskAction,
  fetchUsers: fetchUsersAction,
};

const TasksContainer = connect(mapStateToProps, mapDispatchToProps)(Tasks);

export default TasksContainer;
