import React, { useCallback, useState, useContext, useMemo } from 'react';
import Popover from '@mui/material/Popover';
import DateIcon from '@mui/icons-material/CalendarToday';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { MuiPickersAdapterContext } from '@mui/x-date-pickers';
import { darken, lighten } from '@mui/material/styles';
import DateRangePicker from './DateRangePicker';

const DateRangePickerPopover = ({ value, onChange, formatDate, labels = {}, quickSelectOptions, defaultValues }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { utils } = useContext(MuiPickersAdapterContext);
  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const open = Boolean(anchorEl);
  const id = open ? 'date-range-picker-popover' : undefined;
  const inputText = useMemo(
    () =>
      `${formatDate ? formatDate(value?.fromDate) : utils.format(value?.fromDate, 'keyboardDate')} - ${
        formatDate ? formatDate(value?.toDate) : utils.format(value?.toDate, 'keyboardDate')
      }`,
    [value, formatDate, utils]
  );

  return (
    <>
      <OutlinedInput
        id="date-input"
        sx={theme => ({
          ml: 1,
          width: '250px',
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : darken(theme.palette.background.default, 0.15),
          '&:hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? lighten(theme.palette.background.default, 0.2)
                : darken(theme.palette.background.default, 0.2),
          },
        })}
        onClick={handleClick}
        readOnly={true}
        size="small"
        value={inputText}
        endAdornment={
          <InputAdornment position="end">
            <DateIcon fontSize="small" />
          </InputAdornment>
        }
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { sx: { borderRadius: '10px' } } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <DateRangePicker
          utils={utils}
          defaultValues={defaultValues}
          handleClose={handleClose}
          dates={value}
          updateDates={onChange}
          labels={labels}
          quickSelectOptions={quickSelectOptions}
        />
      </Popover>
    </>
  );
};

export default DateRangePickerPopover;
