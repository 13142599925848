import { styled } from '@mui/material/styles';
import { TimeField } from '@mui/x-date-pickers';

const CustomTimeInputField = styled(TimeField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.surface[2],
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
}));

export default CustomTimeInputField;
