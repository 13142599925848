import React, { useMemo } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { drawSelection, EditorView, lineNumbers } from '@codemirror/view';
import { json } from '@codemirror/lang-json';
import { javascript } from '@codemirror/lang-javascript';
import { xml } from '@codemirror/lang-xml';
import { markdown } from '@codemirror/lang-markdown';
import { sql } from '@codemirror/lang-sql';
import { python } from '@codemirror/lang-python';
import { useParsedValueAndMode } from './parseUtil';
import { materialDark } from '@uiw/codemirror-theme-material';
import { classname } from '@uiw/codemirror-extensions-classname';
import { useTheme } from '@mui/material';
import { css, Global } from '@emotion/react';

const useDiff = content => {
  return useMemo(() => {
    const lines = (content || '').split('\n');
    const removed = lines.map((line, idx) => (line.startsWith('-') ? idx : null)).filter(val => val !== null);
    const added = lines.map((line, idx) => (line.startsWith('+') ? idx : null)).filter(val => val !== null);
    const classnameExt = classname({
      add: lineNumber => {
        if (removed.indexOf(lineNumber - 1) >= 0) {
          return 'cm-removed';
        }
        if (added.indexOf(lineNumber - 1) >= 0) {
          return 'cm-added';
        }
      },
    });
    return classnameExt;
  }, [content]);
};

const modes = {
  javascript: javascript(),
  json: json(),
  markdown: markdown(),
  xml: xml(),
  python: python(),
  'text/x-sql': sql(),
  'application/json': json(),
  'application/xml': xml(),
  'application/text': [],
};

const CodeEditor = ({ content, mode, width, height, onChange, onFocusChangeCallback, diff }) => {
  const { value, mode: parsedMode } = useParsedValueAndMode(content);
  const diffExtension = useDiff(content);
  const theme = useTheme();
  const extensions = useMemo(
    () => [
      lineNumbers(),
      drawSelection(),
      EditorView.lineWrapping,
      modes[mode || parsedMode],
      EditorView.updateListener.of(v => {
        onFocusChangeCallback?.(v.hasFocus);
      }),
      ...(diff ? [diffExtension] : []),
    ],
    [diff, mode, parsedMode, diffExtension, onFocusChangeCallback]
  );
  const diffStyles = useMemo(
    () => css`
      .cm-added {
        background-color: ${theme.palette.success.main}70;
      }
      .cm-removed {
        background-color: ${theme.palette.error.main}70;
      }

      .cm-layer.cm-selectionLayer .cm-selectionBackground,
      .cm-scroller .ͼ16 .cm-content ::selection,
      .ͼ16 .cm-content .cm-line::selection,
      .ͼ4 .cm-scroller .cm-line ::selection {
        background-color: ${theme.palette.primary.main} !important;
      }

      .cm-selectionMatch {
        background-color: ${theme.palette.primary.main}60 !important;
      }
    `,
    [theme]
  );
  return (
    <>
      <Global styles={diffStyles} />
      <CodeMirror
        value={value}
        height={height}
        width={width}
        extensions={extensions}
        theme={materialDark}
        onChange={onChange}
        readOnly={!onChange}
      />
    </>
  );
};

export default CodeEditor;
