import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TaskDescription = ({ description, onChange }) => {
  const intl = useIntl();
  const [descriptionState, setDescription] = useState(description);
  return (
    <Box sx={{ m: 2 }}>
      <TextField
        label={intl.formatMessage({ id: 'Tasks-TaskDetails-details-description' })}
        multiline
        rows={3}
        value={descriptionState}
        onBlur={e => onChange(e.target.value)}
        onChange={e => setDescription(e.target.value)}
        fullWidth
      />
    </Box>
  );
};

export default TaskDescription;
