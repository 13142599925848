import { useEffect } from 'react';
import useMultiScreen from './useMultiScreen';
import { registerBroadcast } from 'util/broadcast';

const useMultiScreenRegister = () => {
  const multiScreen = useMultiScreen();
  useEffect(() => {
    if (multiScreen) {
      const channel = registerBroadcast();
      return () => channel.close();
    }
  }, [multiScreen]);
};

export default useMultiScreenRegister;
