import { combineReducers } from 'redux';

import { reducer as locationDtoReducer, storeKey as locationDtoKey } from './locationDto';
import {
  reducer as identifiedObjectTypePropertyReducer,
  storeKey as identifiedObjectTypePropertyKey,
} from './identifiedObjectTypeProperty';
import { reducer as usagePointLocationDtoReducer, storeKey as usagePointLocationDtoKey } from './usagePointLocationDto';
import { reducer as usagePointReducer, storeKey as usagePointKey } from './usagePoint';
import { reducer as readingsReducer, storeKey as readingsKey } from './readings';
import { reducer as eventsReducer, storeKey as eventsKey } from './events';
import { reducer as issueDtoReducer, storeKey as issueDtoKey } from './issueDto';
import { reducer as issueCategoryReducer, storeKey as issueCategoryKey } from './issueCategory';
import { reducer as tasksReducer, storeKey as tasksKey } from './tasksDuck';
import { reducer as errors, storeKey as errorsKey } from './errorsDuck';
import { reducer as sourcesReducer, storeKey as sourcesKey } from './sourcesDuck';
import { reducer as endDevicesReducer, storeKey as endDevicesKey } from './endDevicesDuck';
import { reducer as assetReducer, storeKey as assetKey } from './asset';

const smoc = combineReducers({
  [errorsKey]: errors,
  [locationDtoKey]: locationDtoReducer,
  [usagePointLocationDtoKey]: usagePointLocationDtoReducer,
  [usagePointKey]: usagePointReducer,
  [identifiedObjectTypePropertyKey]: identifiedObjectTypePropertyReducer,
  [readingsKey]: readingsReducer,
  [eventsKey]: eventsReducer,
  [issueDtoKey]: issueDtoReducer,
  [issueCategoryKey]: issueCategoryReducer,
  [tasksKey]: tasksReducer,
  [sourcesKey]: sourcesReducer,
  [endDevicesKey]: endDevicesReducer,
  [assetKey]: assetReducer,
});

export default smoc;
