import compress from 'graphql-query-compress';
import { datalakeGraphql } from '../../config/backendRoutes';

export const performQuery = (host, token, operation, variables = {}) =>
  new Promise((resolve, reject) => {
    if (host || datalakeGraphql) {
      postData(host || datalakeGraphql, token, {
        ...operation,
        query: compress(operation.query),
        variables,
      })
        .then(response => response.json())
        .then(data => (data.errors && !data.data ? reject(data.errors) : resolve(data.data)))
        .catch(e => reject(e));
    }
  });

function postData(host, token, data = {}) {
  return fetch(host, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  });
}
