import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

import GreenbirdLogo from '../../presentation/components/Logo/Greenbird_bird_icon.svg?react';
import Meatball from '../../presentation/components/Logo/General_Electric_logo.svg?react';
import { navRoutes, Documentation } from '../../config/routes';

import NavigationList from './NavigationList';
import ExternalNavigationListItem from './ExternalNavigationListItem';

const drawerWidth = 210;

const Navigation = ({ userRoles, modules, menuOpen, setMenuOpen }) => {
  const onClose = useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  return (
    <Drawer
      anchor="left"
      PaperProps={{
        elevation: 5,
        sx: {
          overflow: 'visible',
          width: drawerWidth,
          bgcolor: 'background.primary',
          border: 0,
        },
      }}
      open={menuOpen}
      onClose={onClose}
    >
      <Box component="nav" sx={{ overflowY: 'auto' }}>
        <Box sx={{ display: 'flex', p: 2 }}>
          {geThemeFeature ? (
            <Meatball height={42} />
          ) : (
            <>
              <GreenbirdLogo height={42} />
              <Typography sx={{ pl: 1, pt: 1, fontSize: '1.75rem', fontWeight: 300 }}>
                <FormattedMessage id="general.utilihive" defaultMessage="Utilihive" />
              </Typography>
            </>
          )}
        </Box>
        <Divider />
        <NavigationList userRoles={userRoles} modules={modules} routes={navRoutes} open={menuOpen} onClose={onClose} />
        <Divider />
        <List sx={{ px: 1 }}>
          <ExternalNavigationListItem onClose={onClose} {...Documentation} newWindow={true} />
        </List>
      </Box>
    </Drawer>
  );
};

export default Navigation;
