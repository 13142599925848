import moment from 'moment';

export const DATE_OPTIONS = [
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-today' },
    getDate: () => ({
      fromDate: moment().startOf('day'),
      toDate: moment(),
      dateOptionIndex: 0,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-week' },
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .startOf('week'),
      toDate: moment(),
      dateOptionIndex: 1,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastMinutes', values: { minutes: 15 } },
    getDate: () => ({
      fromDate: moment().subtract(15, 'minutes'),
      toDate: moment(),
      dateOptionIndex: 2,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastMinutes', values: { minutes: 30 } },
    getDate: () => ({
      fromDate: moment().subtract(30, 'minutes'),
      toDate: moment(),
      dateOptionIndex: 3,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastHour' },
    getDate: () => ({
      fromDate: moment().subtract(1, 'hours'),
      toDate: moment(),
      dateOptionIndex: 4,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastHours', values: { hours: 24 } },
    getDate: () => ({
      fromDate: moment()
        .startOf('hour')
        .subtract(24, 'hours'),
      toDate: moment(),
      dateOptionIndex: 5,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastDays', values: { days: 7 } },
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(7, 'days'),
      toDate: moment(),
      dateOptionIndex: 6,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastDays', values: { days: 30 } },
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(30, 'days'),
      toDate: moment(),
      dateOptionIndex: 7,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastDays', values: { days: 90 } },
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(90, 'days'),
      toDate: moment(),
      dateOptionIndex: 8,
    }),
  },
  {
    intl: { id: 'FancyDateSelect-CommonlyUsed-option-lastYears', values: { years: 1 } },
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(1, 'years'),
      toDate: moment(),
      dateOptionIndex: 9,
    }),
  },
];
