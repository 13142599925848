import { fetchLoggedInUserOrganizationDetails } from '../api/loginApi';
import { creators } from '../ducks/organizationDuck';
import { logoutAction } from './auth';

export const fetchOrganizationAction = token => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    dispatch(creators.fetch());
    try {
      const response = await fetchLoggedInUserOrganizationDetails({
        token,
      });
      dispatch(creators.fetchSuccess(response));
      resolve();
    } catch (error) {
      dispatch(logoutAction());
      dispatch(creators.fetchFailed(error));
      reject();
    }
  });
