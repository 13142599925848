import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const TaskActions = ({ actions = [] }) => {
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="subtitle1">
        <FormattedMessage id="Tasks-TaskDetails-details-actions-title" />
      </Typography>
      <FormGroup>
        {actions.map(action => (
          <FormControlLabel key={action.actionNumber} control={<Checkbox />} label={action.description} />
        ))}
      </FormGroup>
    </Box>
  );
};

export default TaskActions;
