import React, { useCallback, useEffect, useMemo } from 'react';
import { useLinkClickHandler, useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { navRoutes } from '../../config/routes';
import AppBar from 'sharedComponents/AppBar';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import TasksFilterBarButton from 'Tasks/components/TasksFilterBarButton/TasksFilterBarButtonContainer';
import Tasks from 'Tasks/components/Tasks/TasksContainer';
import { useZitadelContext } from '../ZitadelProvider';

const Navigation = ({ organizationShortName, fetchFeatures, logout, modules }) => {
  const intl = useIntl();
  const showTasks = useFeatureToggle(FEATURE_TOGGLES.TASKS);
  const foundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const { signout: signoutZitadel } = useZitadelContext();

  const onProfileClick = useLinkClickHandler('/settings/user');
  useEffect(() => {
    if (organizationShortName && !foundation) {
      fetchFeatures();
    }
  }, [fetchFeatures, organizationShortName, foundation]);
  const navigate = useNavigate();
  const location = useLocation();
  const current = location.pathname.split('/')[1];
  const onSelect = useCallback(
    e => {
      navigate('/' + e.target.value);
    },
    [navigate]
  );
  const routes = useMemo(
    () =>
      navRoutes
        .filter(route =>
          route.moduleNameForOrganisationAccess ? modules.includes(route.moduleNameForOrganisationAccess) : true
        )
        .map(({ path, intlMessage }) => ({
          path,
          label: intl.formatMessage(intlMessage),
        })),
    [intl, modules]
  );

  const labels = useMemo(
    () => ({
      title: intl.formatMessage({ id: 'general.gridos', defaultMessage: 'GridOS' }),
      product: intl.formatMessage({ id: 'general.connect', defaultMessage: 'Connect' }),
      userButton: intl.formatMessage({ id: 'Toasts-ThemeChange-action', defaultMessage: 'User settings' }),
      logout: {
        label: intl.formatMessage({ id: 'Logout-modalButton-confirmLogout', defaultMessage: 'Log out' }),
        confirmTitle: intl.formatMessage({ id: 'Logout-modalHeader-confirmLogout', defaultMessage: 'Confirm logout' }),
        confirmBody: intl.formatMessage({
          id: 'Logout-modalContent-confirmLogout',
          defaultMessage: 'Are you sure you want to log out?',
        }),
        confirmButton: intl.formatMessage({ id: 'Logout-modalButton-confirmLogout', defaultMessage: 'Log out' }),
      },
    }),
    [intl]
  );
  return (
    <>
      <AppBar
        Toolbar={showTasks && <TasksFilterBarButton />}
        modules={routes}
        currentModulePath={current}
        labels={labels}
        onProfileClick={onProfileClick}
        onLogout={foundation ? signoutZitadel : logout}
        onSelect={onSelect}
      />
      <Tasks />
    </>
  );
};

export default Navigation;
