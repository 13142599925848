import React, { createContext, useContext, useMemo } from 'react';
import { useDateContextParams } from './useDateContextParams';
import { useAutoRefresh } from './useAutoRefresh';

export const DateSelectContext = createContext(null);

export const useDateContext = () => {
  const dateContext = useContext(DateSelectContext);
  if (!dateContext) {
    return {};
  }
  return dateContext;
};

const DateProvider = ({ children, dateOptions, defaultDate, enableAutoRefresh }) => {
  const dateValues = useDateContextParams(dateOptions, defaultDate);
  const [refreshInterval, setRefreshInterval] = useAutoRefresh(
    dateValues.dates,
    dateValues.refreshDates,
    enableAutoRefresh
  );
  const values = useMemo(() => ({ ...dateValues, refreshInterval, setRefreshInterval, enableAutoRefresh }), [
    dateValues,
    setRefreshInterval,
    refreshInterval,
    enableAutoRefresh,
  ]);
  return <DateSelectContext.Provider value={values}>{children}</DateSelectContext.Provider>;
};

export default DateProvider;
