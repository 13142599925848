import React, { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterIcon from '@mui/icons-material/FilterList';

const UserListMenu = ({ value, options, onChange, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterText, setFilterText] = useState('');
  const currentItem = useMemo(() => options.find(item => item.value === value) || { value: -1, label: 'None' }, [
    options,
    value,
  ]);
  const shortList = useMemo(() => {
    return options
      ? options.filter(option => option.label.toLowerCase().indexOf(filterText.toLowerCase()) >= 0).slice(0, 10)
      : [];
  }, [options, filterText]);
  const onMenuClose = () => {
    setAnchorEl(null);
    setFilterText('');
  };
  const onMenuOpen = target => {
    setAnchorEl(target);
  };
  const onSelect = item => {
    onChange(item.value);
    onMenuClose();
  };
  const onTextFieldChange = e => {
    e.stopPropagation();
    setFilterText(e.target.value);
  };
  const onKeyDown = e => {
    if (e.key.length === 1) {
      e.stopPropagation();
    }
  };
  return (
    <>
      <Menu
        autoFocus={false}
        disableAutoFocusItem={true}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
      >
        <MenuItem>
          <TextField
            onKeyDown={onKeyDown}
            autoFocus
            sx={{ p: 1 }}
            variant="standard"
            value={filterText}
            onChange={onTextFieldChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterIcon />
                </InputAdornment>
              ),
            }}
          />
        </MenuItem>
        {shortList.map(item => (
          <MenuItem key={item.value} onClick={() => onSelect(item)}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
      <Button disabled={disabled} color="inherit" onClick={e => onMenuOpen(e.currentTarget)}>
        {currentItem.label}
      </Button>
    </>
  );
};

export default UserListMenu;
