/* global __APP_VERSION__ */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteError } from 'react-router-dom';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';
import AppFallbackUI from './FallbackUI/AppFallbackUI';
import SceneFallbackUI from './FallbackUI/SceneFallbackUI';
import { errorLogging } from '../../api/loggingApi';
import { selectors as authSelectors } from '../../ducks/authDuck';

const sendErrorReport = async (token, error, signal) => {
  const errorInfo = {
    userAgent: window.navigator.userAgent,
    errorMessage: `${error.toString()}\n\n${error.stack}`,
    errorLevel: 'ERROR',
    clientVersion: __APP_VERSION__,
    currentUrl: window.location.href,
  };
  try {
    await errorLogging(token, errorInfo, signal);
  } catch {
    console.error('Error logging failed');
  }
};

const ErrorBoundary = ({ appFallbackUI }) => {
  const isFoundation = useFeatureToggle(FEATURE_TOGGLES.FOUNDATION);
  const error = useRouteError();
  const token = useSelector(authSelectors.token);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (error && isFoundation && !import.meta.env.DEV) {
      sendErrorReport(token, error, signal);
    }
    return () => {
      controller.abort();
    };
  }, [error, isFoundation, token]);
  return appFallbackUI ? <AppFallbackUI /> : <SceneFallbackUI />;
};

export default ErrorBoundary;
