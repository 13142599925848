import { selectors as authSelectors } from 'App/ducks/authDuck';
import { selectors as userSelectors } from 'App/ducks/userDuck';
import { fetchUsers } from '../api/userApi';
import { creators } from '../ducks/usersDuck';

export const fetchUsersAction = () => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const activeRole = userSelectors.activeRole(state);
  dispatch(creators.fetch());
  try {
    const data = await fetchUsers(token, activeRole.organizationId);
    dispatch(creators.fetchSuccess(data));
  } catch (e) {
    dispatch(creators.fetchFailed(e));
  }
};
