import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import PublicPageWrapper from './PublicPageWrapper';
import Logo from '../Logo';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GE_THEME } from 'config/features';

const paperSx = {
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: 2,
  px: 3,
  ml: {
    sx: 3,
    sm: 'auto',
  },
  mr: {
    sx: 3,
    sm: 'auto',
  },
  width: {
    sm: '400px',
  },
};

const PublicPageFormPaper = ({ children }) => (
  <PublicPageWrapper>
    <CssBaseline />
    <Paper sx={paperSx}>
      <Box sx={{ width: '50%', display: 'flex', justifyContent: 'center' }}>
        {GE_THEME ? (
          <Box sx={{ my: 5 }}>
            <Typography variant="h2">
              <FormattedMessage id="general.gridos" defaultMessage="GridOS" />
            </Typography>
          </Box>
        ) : (
          <Logo />
        )}
      </Box>
      {children}
    </Paper>
  </PublicPageWrapper>
);

export default PublicPageFormPaper;
