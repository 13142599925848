import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import TaskListItem from './TasksListItem';
import EmptyListItem from './EmptyListItem';

const TasksList = ({ tasks, selectTask, users }) => {
  return (
    <>
      <div>
        <Typography sx={{ p: 2, textAlign: 'center' }} variant="h6">
          <FormattedMessage id="Tasks-TasksList-title" />
        </Typography>
      </div>
      <Divider />
      <List>
        {tasks.map(task => (
          <TaskListItem key={task.id} task={task} selectTask={selectTask} users={users} />
        ))}
        {tasks.length === 0 && <EmptyListItem />}
      </List>
    </>
  );
};

export default TasksList;
