import useFeatureToggle from './useFeatureToggle';

const FeatureToggle = ({ children, name, whenDisabled }) => {
  const featureToggle = useFeatureToggle(name);
  if (featureToggle && !whenDisabled) {
    return children;
  } else if (!featureToggle && whenDisabled) {
    return children;
  }
  return null;
};

export default FeatureToggle;
