import Duck from 'extensible-duck';
import genericEntityDuck from '../../util/genericEntityDuck';
import { defineMessage } from 'react-intl';
import { GE_THEME, FOUNDATION } from 'config/features';

const storeKey = 'features';
const namespace = 'app';

export const FEATURE_TOGGLES = {
  FLOW_CONTROL: 'flowControl',
  FLOW_SPEC_EDIT: 'flowSpecEdit',
  LEGACY_API_SPEC_URL: 'legacyApiSpecUrl',
  RESUBMIT_TRACES: 'resubmitTraces',
  MULTI_SCREEN: 'multiScreen',
  ALERTS: 'alerts',
  REPORTING: 'reporting',
  THEMING: 'theming',
  MICROSOFT_LOGIN: 'microsoftLogin',
  BEATBOX: 'beatbox',
  TASKS: 'tasks',
  GHOSTWRITER_DEMO: 'ghostwriterDemo',
  METRICS_GRAPH: 'metricsGraph',
  SMOC_MISSING_DATA: 'smocMissingData',
  SMOC_DATA_SERVICE: 'smocDataService',
  SMOC_SOURCES: 'smocSources',
  FLOW_TRACES_V2: 'flowTracesV2',
  GE_THEME: 'geTheme',
  FOUNDATION: 'foundation',
  FLOW_TRACES_FORWARDING_PATH: 'flowTracesForwardingPath',
};

const GE_THEME_STORAGE_KEY = 'UTILIHIVE:geTheme';

export const FEATURE_DEFINITIONS = [
  {
    key_id: FEATURE_TOGGLES.ALERTS,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-alerts-name', defaultMessage: 'Alerts' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-alerts-description',
      defaultMessage: 'Alerts page in Heartbeat',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.BEATBOX,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-beatbox-name', defaultMessage: 'Beatbox' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-beatbox-description',
      defaultMessage: 'Beatbox page in Heartbeat',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.TASKS,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-tasks-name', defaultMessage: 'Tasks' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-tasks-description',
      defaultMessage: 'Tasks in the filter bar',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.MULTI_SCREEN,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-multiScreen-name', defaultMessage: 'Multi-screen' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-multiScreen-description',
      defaultMessage: 'Experimental multi-screen',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.FLOW_CONTROL,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-flowControl-name', defaultMessage: 'Flow control' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-flowControl-description',
      defaultMessage: 'Add capabilities for pausing and starting flows',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.FLOW_SPEC_EDIT,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-flowSpecEdit-name', defaultMessage: 'FlowSpec Edit' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-flowSpecEdit-description',
      defaultMessage: 'Add possibility to edit flowSpec',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.GE_THEME,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-geTheme-name', defaultMessage: 'GE Theme' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-geTheme-description',
      defaultMessage: 'Turn on GE Theme',
    }),
  },
  {
    key_id: FEATURE_TOGGLES.RESUBMIT_TRACES,
    nameIntl: defineMessage({ id: 'FeatureToggle-definition-resubmitTraces-name', defaultMessage: 'Resubmit traces' }),
    descriptionIntl: defineMessage({
      id: 'FeatureToggle-definition-resubmitTraces-description',
      defaultMessage: 'Turn on resubmit in flow traces',
    }),
  },
];

const duck = genericEntityDuck(namespace, storeKey, 'id', namespace).extend({
  initialState: {
    data: {
      flowControl: false,
      flowSpecEdit: false,
      legacyApiSpecUrl: false,
      resubmitTraces: false,
      alerts: false,
      reporting: false,
      theming: false,
      multiScreen: false,
      microsoftLogin: false,
      beatbox: false,
      tasks: false,
      ghostwriterDemo: false,
      metricsGraph: false,
      smocMissingData: false,
      smocDataService: false,
      smocSources: false,
      geTheme: localStorage.getItem(GE_THEME_STORAGE_KEY) === 'true' || GE_THEME || false,
      foundation: FOUNDATION || false,
      ...window.features,
      flowTracesForwardingPath: false,
    },
  },
  types: ['FETCH_FEATURES_SUCCESS', 'UPDATE_FEATURE'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.FETCH_FEATURES_SUCCESS:
        return {
          ...state,
          data: {
            ...state.data,
            ...action.data,
            geTheme: action.data.geTheme || GE_THEME || false,
            foundation: action.data.foundation || FOUNDATION || false,
          },
          initialized: true,
          loading: state.createLoading || state.updateLoading || state.deleteLoading,
          fetchLoading: false,
          error: undefined,
          fetchError: undefined,
        };
      case types.UPDATE_FEATURE:
        return { ...state, data: { ...state.data, [action.key]: action.value } };
      default:
        return state;
    }
  },
  selectors: {
    features: new Duck.Selector(selectors => state => selectors.data(state)),
  },
  creators: ({ types }) => ({
    fetchSuccess: data => ({ type: types.FETCH_FEATURES_SUCCESS, data }),
    updateFeature: data => ({ type: types.UPDATE_FEATURE, key: data.key, value: data.value }),
  }),
});

export { duck as default, storeKey };
export const { creators, reducer, types, selectors } = duck;
