import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MessageBox from 'sharedComponents/MessageBox';
import { TextFieldInput } from 'sharedComponents/Form';

import { getColorByIndex } from '../../presentation/colors';
import { PublicPageFormPaper } from '../../presentation/components/PublicPageWrapper';
import { isPasswordResetTokenValid, resetPassword } from '../../api/loginApi';

const SetPassword = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState(undefined);
  const [pwStrength, setPwStrength] = useState(undefined);
  const intl = useIntl();

  useEffect(() => {
    if (!loading && !initialized) {
      const fetchData = async () => {
        if (!params) {
          setError(
            intl.formatMessage({
              id: 'Login-invalidParam-missingParams',
              defaultMessage: 'Missing verification parameters in URL',
            })
          );
          return;
        }
        if (!params.token) {
          setError(
            intl.formatMessage({ id: 'Login-invalidParam-missingToken', defaultMessage: 'Missing token in URL' })
          );
          return;
        }
        setLoading(true);
        try {
          await isPasswordResetTokenValid(params.token);
          setLoading(false);
          setInitialized(true);
        } catch (e) {
          setError(intl.formatMessage({ id: 'Login-invalidParam-invalidToken', defaultMessage: 'Invalid token' }));
          setLoading(false);
          setInitialized(true);
        }

        setPwStrength(await import(/* webpackChunkName: "App:zxcvbn" */ 'zxcvbn'));
      };

      fetchData();
    }
  }, [params, loading, initialized, intl]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      requestedPassword: '',
      repeatedPassword: '',
    },
  });

  const setPassword = async ({ requestedPassword, repeatedPassword }) => {
    try {
      setLoading(true);
      if (!params.token) {
        throw Error(
          intl.formatMessage({ id: 'Login-invalidParam-missingToken', defaultMessage: 'Missing token in URL' })
        );
      }
      await resetPassword(params.token, requestedPassword, repeatedPassword);
      setLoading(false);
      setReset(true);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };

  const getPwStrength = pw => pw && pwStrength && pwStrength.default(pw).score;
  const MIN_REQUIRED_PW_STRENGTH_LEVEL = 2;

  const passwordLabel = intl.formatMessage({ id: 'Login-input-password', defaultMessage: 'Password' });
  const repeatPasswordLabel = intl.formatMessage({
    id: 'Login-input-repeatPassword',
    defaultMessage: 'Repeat password',
  });
  const setButtonLabel = intl.formatMessage({ id: 'Login-button-setPassword', defaultMessage: 'Set password' });
  const resetSuccessLabel = intl.formatMessage({ id: 'Login-anchor-login', defaultMessage: 'Proceed to login.' });
  const errorLabel = error;
  const passwordStrength = getPwStrength(watch('requestedPassword'));

  return (
    <PublicPageFormPaper>
      <form onSubmit={handleSubmit(setPassword)}>
        <MessageBox message={errorLabel} />
        <TextFieldInput
          registerProps={register('requestedPassword', {
            required: true,
            validate: {
              passwordStrength: value =>
                getPwStrength(value) >= MIN_REQUIRED_PW_STRENGTH_LEVEL ? undefined : 'Login-error-passwordTooWeak',
            },
          })}
          type="password"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          label={passwordLabel}
          autoComplete="new-password"
          InputLabelProps={{}}
          errors={errors}
        />
        <Box
          sx={{ transition: 'all 0.5s' }}
          style={{
            height: watch('requestedPassword') ? 5 : 0,
            width: `${(passwordStrength + 1 || 0) * 20}%`,
            background: getColorByIndex(Math.min(passwordStrength, 3)),
          }}
        />
        <TextFieldInput
          registerProps={register('repeatedPassword', {
            required: true,
            validate: {
              equal: value => (value === watch('requestedPassword') ? undefined : 'Login-error-passwordsMustMatch'),
            },
          })}
          type="password"
          required
          fullWidth
          variant="outlined"
          margin="normal"
          label={repeatPasswordLabel}
          autoComplete="new-password"
          InputLabelProps={{}}
          errors={errors}
        />
        {reset ? (
          <div>
            <MessageBox
              variant="success"
              message={<FormattedMessage id="Login-label-passwordChanged" defaultMessage="Password set." />}
            />
            <Button component={Link} to="/login" fullWidth variant="contained" color="primary" sx={{ my: 2 }}>
              {resetSuccessLabel}
            </Button>
          </div>
        ) : (
          <Button
            type={error ? undefined : 'submit'}
            fullWidth
            variant="contained"
            color="primary"
            disabled={Boolean(error)}
            sx={{ my: 2 }}
          >
            {setButtonLabel}
          </Button>
        )}
      </form>
      {reset && (
        <Button component={Link} to="/" fullWidth>
          <FormattedMessage id="Login-anchor-back" defaultMessage="Return to login" />
        </Button>
      )}
    </PublicPageFormPaper>
  );
};

export default SetPassword;
