import { styled } from '@mui/material/styles';
import { DateField } from '@mui/x-date-pickers/DateField';

const CustomDateInputField = styled(DateField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.surface[2],
  },
}));

export default CustomDateInputField;
