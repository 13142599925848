import React from 'react';
import { MenuItem } from '@mui/material';
import ConfirmDialog from 'sharedComponents/ConfirmDialog/ConfirmDialog';

const Logout = ({ logout, labels = {} }) => {
  return (
    <ConfirmDialog
      title={labels.confirmTitle || 'Confirm logout'}
      contentText={labels.confirmBody || 'Are you sure you want to log out?'}
      confirmText={labels.confirmButton || 'Log out'}
      onConfirm={logout}
    >
      <MenuItem sx={{ color: 'error.main' }}>{labels.label || 'Log out'}</MenuItem>
    </ConfirmDialog>
  );
};

export default Logout;
