import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { hooks } from 'App/ducks/configurationDuck';

const DEFAULT_TIMEZONE = 'UTC';

export const VARIANTS = {
  time: 'LTS',
  'time-iso': 'HH:mm:ss.SSS',
  dateTime: 'L LTS',
  date: 'L',
  timestamp: 'YYYY-MM-DDTHH:mm:ss.SSS',
};

const useDateFormatter = sourceTimeZoneForAll => {
  const configuration = hooks.useConfigurationSelector();
  const getMomentDate = useCallback(
    (date, sourceTimeZone, destinationTimeZone) => {
      const mDate = moment.tz(date, sourceTimeZoneForAll || sourceTimeZone || DEFAULT_TIMEZONE);
      return mDate.tz(destinationTimeZone || configuration.timeZone);
    },
    [configuration, sourceTimeZoneForAll]
  );
  const dateFormatter = useMemo(() => {
    return {
      dateTime: (date, sourceTimeZone, destinationTimeZone) =>
        getMomentDate(date, sourceTimeZone, destinationTimeZone).format(VARIANTS.dateTime),
      date: (date, sourceTimeZone, destinationTimeZone) =>
        getMomentDate(date, sourceTimeZone, destinationTimeZone).format(VARIANTS.date),
      time: (date, sourceTimeZone, destinationTimeZone) =>
        getMomentDate(date, sourceTimeZone, destinationTimeZone).format(VARIANTS.time),
      timestamp: (date, sourceTimeZone, destinationTimeZone) =>
        getMomentDate(date, sourceTimeZone, destinationTimeZone).format(VARIANTS.timestamp),
      moment: (date, sourceTimeZone, destinationTimeZone) => getMomentDate(date, sourceTimeZone, destinationTimeZone),
      iso: (date, sourceTimeZone, destinationTimeZone) =>
        getMomentDate(date, sourceTimeZone, destinationTimeZone).toISOString(),
      format: (date, sourceTimeZone, destinationTimeZone) => {
        const momentObj = getMomentDate(date, sourceTimeZone, destinationTimeZone);
        return momentObj.format.bind(momentObj);
      },
    };
  }, [getMomentDate]);
  return dateFormatter;
};

export default useDateFormatter;
