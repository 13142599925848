import { grey } from '@mui/material/colors';

const surface = {
  '1': '#FFFFFF',
  '2': '#FCFCFC',
  '3': '#F5F5F6',
  '4': '#EDEFF0',
  '5': '#EBECED',
  '6': '#D2D5D7',
  '7': '#474D4F',
  '8': '#EDEFF0',
};

const borders = {
  white: '#FFFFFF',
  minimal: '#F0F0F0',
  subtle: '#ECECEE',
  moderate: '#D2D5D7',
  bold: '#B5BECA',
  strong: '#4C6472',
  focus: '#1C86EC',
  black: '#121F26',
};

const text = {
  primary: '#121F26',
  secondary: '#425866',
  tertiary: '#6A7585',
  interaction: '#005EB8',
  destructive: '#B40000',
  reverse: '#FFFFFF',
  disabled: '#87898C',
  labelwhite: '#FFFFFF',
  labelblack: '#121F26',
};

const icons = {
  default: '#425866',
  hover: '#005eb8',
  disabled: '#87898C',
  destructive: '#b40000',
};

const feedback = {
  information: '#1479C9',
  danger: '#B40000',
  serious: '#FF8B3A',
  warning: '#FEC602',
  success: '#7fAF1C',
  neutral: '#D8E0E5',
  neutralAlt: '#E5F5FF',
  danger2: '#ED5120',
  warning2: '#FFE68B',
};

const workflow = {
  new: '#0665BC',
  draft: '#4D4D4D',
  submitted: '#3845BA',
  reviewing: '#7841D2',
  evaluating: '#B52D8E',
  inProgress: '#B86900',
  complete: '#27516D',
  approved: '#3D7713',
  rejected: '#AD254E',
};

const dataWiz = {
  '1': '#82D2FB',
  '2': '#5ABFF8',
  '3': '#3F92CB',
  '4': '#99BAD0',
  '5': '#3E7091',
  '6': '#315C7E',
  '7': '#DE5558',
  '8': '#82BDB1',
  '9': '#5DAC9F',
  '10': '#3F8678',
  '11': '#31675C',
  '12': '#9DA7FF',
  '13': '#6D81FF',
  '14': '#4F6AFF',
  '15': '#4156D1',
  '16': '#EBB275',
  '17': '#D97A14',
  '18': '#B66612',
  '19': '#DBD048',
  '20': '#B4AA23',
  '21': '#E496BD',
  '22': '#902B49',
  '23': '#7A7A7A',
  '24': '#CCB3F5',
  '25': '#A67BEC',
  '26': '#A5D671',
  '27': '#7DBB22',
  '28': '#507A13',
};

const paletteLight = {
  mode: 'light',
  primary: { main: text.interaction },
  secondary: { main: '#e0e1e3' },
  success: { main: feedback.success },
  warning: { main: feedback.warning },
  error: { main: '#f34336', dark: feedback.danger },
  info: { main: feedback.information },
  borders,
  surface,
  icons,
  workflow,
  dataWiz,
  feedback,
  text,
  background: {
    paper: surface['3'],
    default: surface['3'],
    contrast: grey[900],
    primary: surface['5'],
  },
  alternateTable: {
    main: '#3a3a3a',
  },
};

export default paletteLight;
