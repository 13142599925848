import { fetchLoggedInUserDetails } from '../api/loginApi';
import { creators as userCreators } from '../ducks/userDuck';
import { logoutAction } from './auth';

export const fetchUserAction = token => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    dispatch(userCreators.fetch());
    try {
      const response = await fetchLoggedInUserDetails({
        token,
      });
      dispatch(userCreators.fetchSuccess(response));
      resolve();
    } catch (error) {
      dispatch(logoutAction());
      dispatch(userCreators.fetchFailed(error));
      reject();
    }
  });
