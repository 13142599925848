import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { defineMessage } from 'react-intl';
import qs from 'qs';
import OverviewIcon from 'utilihive-icons/Overview';
import FlowsIcon from 'utilihive-icons/Flow';
import HeartbeatIcon from 'utilihive-icons/Heartbeat';
import FlowTracesIcon from '@mui/icons-material/Reorder';
import ServiceAccountIcon from '@mui/icons-material/Lock';
import ResourceIcon from '@mui/icons-material/Description';
import AlertsIcon from '@mui/icons-material/Notifications';
import ReportingIcon from '@mui/icons-material/Assessment';
import BeatboxIcon from '@mui/icons-material/MonitorHeart';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import { USER_ROLES } from 'App/domain/user';
import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'heartbeat';

export const OVERVIEW = '';
export const FLOWS = 'flows';
export const FLOW_TRACES = 'flow-traces';
export const FLOW = 'flows/:id/*';
export const FLOW_ACCESS = 'flow-access';
export const SERVICE_ACCOUNTS = 'service-accounts';
export const SERVICE_ACCOUNT = 'service-accounts/:id';
export const RESOURCES = 'resources';
export const LOOKUP_TABLE = 'resources/LookupTable/:tableName';
export const SECRETS_DETAILS = 'resources/secrets/:key';
export const RESOURCE = 'resources/:type/:id/:revision';
export const ALERTS = 'alerts';
export const CREATE_ALERT = 'alerts/create';
export const EDIT_ALERT = 'alerts/edit/:alertId';
export const REPORTING = 'reporting';
export const BEATBOX = 'beatbox';
export const BEATBOX_FLOW = 'flows/beatbox/:beatboxId/:id/*';

const paramFields = ['fromDate', 'toDate', 'dateOptionIndex', 'offset'];

const getParams = search => {
  const cleaned = search.replace('?', '');
  const params = qs.parse(cleaned);
  const newParams = {};
  paramFields.forEach(fieldKey => {
    if (params[fieldKey]) {
      newParams[fieldKey] = params[fieldKey];
    }
  });
  const stringified = qs.stringify(newParams);
  return stringified ? `?${stringified}` : '';
};

const children = [
  {
    index: true,
    path: OVERVIEW,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Overview" */ '../scenes/Overview'),
    intlMessage: defineMessage({ id: 'route.heartbeat.overview', defaultMessage: 'Overview' }),
    Icon: OverviewIcon,
  },
  {
    path: FLOWS,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Flows" */ '../scenes/Flows'),
    intlMessage: defineMessage({ id: 'route.heartbeat.flows', defaultMessage: 'Flows' }),
    Icon: FlowsIcon,
  },
  {
    path: FLOW,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Flow" */ '../scenes/Flow'),
  },
  {
    path: FLOW_TRACES,
    lazy: () => import(/* webpackChunkName: "Heartbeat:FlowTraces" */ '../scenes/FlowTraces'),
    intlMessage: defineMessage({ id: 'route.heartbeat.flowTraces', defaultMessage: 'Flow traces' }),
    Icon: FlowTracesIcon,
  },
  {
    path: RESOURCES,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Resources" */ '../scenes/Resources'),
    intlMessage: defineMessage({ id: 'route.heartbeat.resources', defaultMessage: 'Resources' }),
    Icon: ResourceIcon,
  },
  {
    path: RESOURCE,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Resource" */ '../scenes/Resource'),
  },
  {
    path: LOOKUP_TABLE,
    lazy: () => import(/* webpackChunkName: "Heartbeat:LookupTable" */ '../scenes/LookupTable'),
  },
  {
    path: SECRETS_DETAILS,
    lazy: () => import(/* webpackChunkName: "Heartbeat:SecretsDetails" */ '../scenes/Secrets'),
  },
  {
    path: SERVICE_ACCOUNTS,
    lazy: () => import(/* webpackChunkName: "Heartbeat:ServiceAccounts" */ '../scenes/ServiceAccounts'),
    intlMessage: defineMessage({ id: 'route.heartbeat.serviceAccounts', defaultMessage: 'Service accounts' }),
    Icon: ServiceAccountIcon,
  },
  {
    path: SERVICE_ACCOUNT,
    lazy: () => import(/* webpackChunkName: "Heartbeat:ServiceAccount" */ '../scenes/ServiceAccount'),
  },
  {
    path: ALERTS,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Alerts" */ '../scenes/Alerts'),
    intlMessage: defineMessage({ id: 'route.heartbeat.alerts', defaultMessage: 'Alerts' }),
    Icon: AlertsIcon,
    featureToggle: FEATURE_TOGGLES.ALERTS,
    restrictRoleAccess: USER_ROLES.DEPLOYER,
  },
  {
    path: CREATE_ALERT,
    lazy: () => import(/* webpackChunkName: "Heartbeat:CreateAlert" */ '../scenes/Alert/CreateAlert'),
  },
  {
    path: EDIT_ALERT,
    lazy: () => import(/* webpackChunkName: "Heartbeat:CreateAlert" */ '../scenes/Alert/EditAlert'),
  },
  {
    path: REPORTING,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Reporting" */ '../scenes/Reporting'),
    intlMessage: defineMessage({ id: 'route.heartbeat.reporting', defaultMessage: 'Reporting' }),
    Icon: ReportingIcon,
    featureToggle: FEATURE_TOGGLES.REPORTING,
  },
  {
    path: BEATBOX,
    lazy: () => import(/* webpackChunkName: "Heartbeat:Beatbox" */ '../scenes/Beatbox'),
    intlMessage: defineMessage({ id: 'route.heartbeat.beatbox', defaultMessage: 'Beatbox' }),
    Icon: BeatboxIcon,
    featureToggle: FEATURE_TOGGLES.BEATBOX,
    restrictRoleAccess: USER_ROLES.DEPLOYER,
  },
  {
    path: BEATBOX_FLOW,
    lazy: () => import(/* webpackChunkName: "Heartbeat:BeatboxFlow" */ '../scenes/BeatboxFlow'),
  },
];

const heartbeat = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Heartbeat" */ 'Heartbeat'),
  intlMessage: defineMessage({ id: 'route.heartbeat', defaultMessage: 'Heartbeat' }),
  Icon: <HeartbeatIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.HEARTBEAT,
  children,
};

export const useRoutes = () => {
  const location = useLocation();
  const routes = useMemo(
    () => ({
      intlMessage: heartbeat.intlMessage,
      Icon: heartbeat.Icon,
      root: heartbeat.path,
      moduleNameForOrganisationAccess: heartbeat.moduleNameForOrganisationAccess,
      routes: heartbeat.children
        .filter(child => !!child.intlMessage)
        .map(({ path, Icon, featureToggle, restrictRoleAccess, intlMessage, index }) => ({
          index,
          path,
          intlMessage,
          Icon,
          featureToggle,
          restrictRoleAccess,
          params: getParams(location.search),
        })),
    }),
    [location.search]
  );
  return routes;
};

export default heartbeat;
