import { performQuery } from '../util/graphql-client';

const queryIssueDtoMrIdList = {
  operationName: 'queryIssue',
  query: `
    query queryIssue($mrIds: [String!], $source: URI!) {
      issueDto: queryIssue(
        source:  $source,
        filter: {
            mrId: {
                in: $mrIds
            },
        },
      ) {
        mrId
        lastEventDateTime
        category
        description
        configurationEvent {
          createdDateTime
          effectiveDateTime
        }
        asset {
          mrId
        }
      }
  }
`,
};

const queryIssueDtoList = {
  operationName: 'queryIssue',
  query: `
      query queryIssue($source: URI!,$first: Int, $offset: Int, $mappedFilters: [DomainObjectFilterInput!], $location: DomainObjectFilterInput) {
      issueDto: queryIssue(
        first: $first
        offset: $offset
        source: $source,
        filter: {
            and: $mappedFilters
        }
      ) { 
        category
        description
        mrId
        lastEventDateTime
        configurationEvent {
          createdDateTime
          effectiveDateTime
        }
        issuerId
        severity
        asset {
          mrId
          __typename
        }
        location(filter: $location) {
          positionPoints {
            xposition
            yposition
          }
        }
      }
  }`,
};

export const fetchIssueDtoMrIdList = (host, token, organization, mrIds, source) =>
  performQuery(host, token, queryIssueDtoMrIdList, {
    organization,
    mrIds,
    source,
  });

export const fetchIssueDtoList = (
  dataLakeGraphqlHost,
  token,
  organization,
  first,
  offset,
  source,
  mappedFilters,
  { polygon }
) =>
  performQuery(dataLakeGraphqlHost, token, queryIssueDtoList, {
    organization,
    first,
    offset,
    source,
    mappedFilters,
    location: polygon && {
      positionPoints: {
        intersects: {
          type: 'Polygon',
          coordinates: [polygon.map(p => [p.longitude, p.latitude])],
        },
      },
    },
  });
