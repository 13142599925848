import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const TaskStatus = () => {
  const [showMenu, setShowMenu] = useState(null);
  const handleClose = () => {
    setShowMenu(null);
  };
  return (
    <>
      <Button onClick={e => setShowMenu(e.currentTarget)}>
        <FormattedMessage id="Tasks-TaskDetails-details-status-open" />
      </Button>
      <Menu anchorEl={showMenu} open={!!showMenu} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <FormattedMessage id="Tasks-TaskDetails-details-status-closed-resolved" />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FormattedMessage id="Tasks-TaskDetails-details-status-closed-unresolved" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default TaskStatus;
