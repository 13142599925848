import { connect } from 'react-redux';
import { selectors as userSelectors } from 'App/ducks/userDuck';
import { setActiveRole } from 'App/actions/role';
import Footer from './Footer';

const mapStateToProps = state => ({
  user: userSelectors.user(state),
  activeRole: userSelectors.activeRole(state),
});

const mapDispatchToProps = {
  setActiveRole,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
