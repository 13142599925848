import React, { useMemo } from 'react';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import GridOSThemeProvider from 'sharedComponents/ThemeProvider';
import { FEATURE_TOGGLES } from '../ducks/featureDuck';
import darkTheme from './greenbird/darkTheme';

const ThemeProvider = ({ children }) => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);

  const theme = useMemo(() => {
    if (!geThemeFeature) {
      return darkTheme;
    }
  }, [geThemeFeature]);
  return <GridOSThemeProvider theme={theme}>{children}</GridOSThemeProvider>;
};

export default ThemeProvider;
