import { selectors as authSelectors } from 'App/ducks/authDuck';
import { selectors as userSelectors } from 'App/ducks/userDuck';
import { selectors as configurationSelectors } from 'App/ducks/configurationDuck';
import { creators as issuesDtoCreators } from '../ducks/issueDto';
import { fetchIssueDtoList, fetchIssueDtoMrIdList } from '../api/issueDto';

export const fetchIssueDtoAction = (
  first,
  offset,
  source,
  mappedFilters,
  mappedTargetObjectFilter,
  order,
  polygon
) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const organization = userSelectors.organizationShortName(state);
  const dataLakeGraphqlHost = configurationSelectors.configuration(state).dataLakeGraphqlHost;
  try {
    dispatch(issuesDtoCreators.fetch());
    const result = await fetchIssueDtoList(
      dataLakeGraphqlHost,
      token,
      organization,
      first,
      offset,
      source,
      mappedFilters,
      { polygon }
    );
    dispatch(issuesDtoCreators.fetchSuccess(result.issueDto.map(issue => ({ ...issue, source }))));
  } catch (e) {
    dispatch(issuesDtoCreators.fetchFailed(e));
  }
};

export const fetchIssueDtoListAction = (mrIds, source) => async (dispatch, getState) => {
  const state = getState();
  const token = authSelectors.token(state);
  const organization = userSelectors.organizationShortName(state);
  const dataLakeGraphqlHost = configurationSelectors.configuration(state).dataLakeGraphqlHost;
  try {
    const result = await fetchIssueDtoMrIdList(dataLakeGraphqlHost, token, organization, mrIds, source);
    return result.issueDto;
  } catch (e) {
    return [];
  }
};
