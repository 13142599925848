import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import TasksIcon from '@mui/icons-material/Inbox';
import React from 'react';
import { useIntl } from 'react-intl';

const TasksFilterBarButton = ({ hasNewTasks, setShowTasksList }) => {
  const intl = useIntl();

  return (
    <div>
      <Tooltip
        title={
          hasNewTasks
            ? intl.formatMessage({ id: 'Tasks-filterBar-tooltip-hasNewTasks' })
            : intl.formatMessage({ id: 'Tasks-filterBar-tooltip-openTasksList' })
        }
      >
        <Button sx={{ pl: 0.5, pr: 0.5, minWidth: 40 }} color="inherit" onClick={() => setShowTasksList(true)}>
          {hasNewTasks ? (
            <Badge color="primary" variant="dot">
              <TasksIcon />
            </Badge>
          ) : (
            <TasksIcon />
          )}
        </Button>
      </Tooltip>
    </div>
  );
};

export default TasksFilterBarButton;
