export const HEARTBEAT = 'HEARTBEAT';
export const GHOSTWRITER = 'GHOSTWRITER';
export const DATALAKE = 'DATA_LAKE';
export const SMOC = 'SMOC';
export const VEE = 'VEE';
export const PLATFORM = 'PLATFORM';
export const ASSETS = 'ASSETS';
export const SECURITY = 'SECURITY';

const domainModel = {
  HEARTBEAT,
  DATA_LAKE: DATALAKE,
  SMOC,
  GHOSTWRITER,
  VEE,
  PLATFORM,
  ASSETS,
  SECURITY,
};

export const mapApiToModel = data => {
  return {
    modules: data.modules
      .filter(module => Object.keys(domainModel).includes(module))
      .map(module => domainModel[module]),
  };
};

const allModules = { HEARTBEAT, GHOSTWRITER, DATALAKE, SMOC, VEE, PLATFORM, ASSETS, SECURITY };

export default allModules;
