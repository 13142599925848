import moment from 'moment';

export const isEventValid = event => {
  return (
    typeof event.type === 'string' &&
    event.type.length > 0 &&
    moment.isMoment(event.timeStart) &&
    moment.isMoment(event.timeEnd) &&
    event.timeStart.isBefore(event.timeEnd) &&
    typeof event.polygonArea !== 'undefined'
  );
};

export const isEventScenarioValid = eventScenario => {
  return (
    eventScenario.events &&
    eventScenario.events.length > 0 &&
    eventScenario.events.reduce((res, current) => isEventValid(current) && res, true) &&
    typeof eventScenario.name === 'string' &&
    eventScenario.name.length > 0
  );
};

export const eventScenarioEventTypes = {
  COMMUNICATION_OUT: 'COMMUNICATION_OUT',
  POWER_OUT: 'POWER_OUT',
  METER_FAIL: 'METER_FAIL',
  MISSING_READING_FAIL: 'MISSING_READING_FAIL',
  RCD_SWITCH_DISCONNECT: 'RCD_SWITCH_DISCONNECT',
  GROUND_FAULT: 'GROUND_FAULT',
  VOLTAGE_FAULT: 'VOLTAGE_FAULT',
  CORRUPTED_READ_START: 'CORRUPTED_READ_START',
  POWER_FAIL_AND_MISSING_EVENT_START: 'POWER_FAIL_AND_MISSING_EVENT_START',
  METER_TAMPERING: 'METER_TAMPERING',
  VOLTAGE_IMBALANCE: 'VOLTAGE_IMBALANCE',
  CURRENT_IMBALANCE: 'CURRENT_IMBALANCE',
  SPIKE_READ: 'SPIKE_READ',
  NEGATIVE_READ: 'NEGATIVE_READ',
  RIVA_DLMS_DEVICE_RECONFIGURED: 'RIVA_DLMS_DEVICE_RECONFIGURED',
  RIVA_DLMS_DST_PERIODS_EXHAUSTED: 'RIVA_DLMS_DST_PERIODS_EXHAUSTED',
  RIVA_DLMS_TIME_ADJUSTMENT_FAILED: 'RIVA_DLMS_TIME_ADJUSTMENT_FAILED',
  RIVA_DLMS_TIME_INVALID: 'RIVA_DLMS_TIME_INVALID',
  RIVA_DLMS_DEAD_BATTERY_DETECTED: 'RIVA_DLMS_DEAD_BATTERY_DETECTED',
  RIVA_DLMS_SYSTEM_RESTART: 'RIVA_DLMS_SYSTEM_RESTART',
  RIVA_DLMS_TIME_OUTSIDE_AUTOMATIC_SYNC_THRESHOLD: 'RIVA_DLMS_TIME_OUTSIDE_AUTOMATIC_SYNC_THRESHOLD',
  RIVA_DLMS_WIFI_DISABLED: 'RIVA_DLMS_WIFI_DISABLED',
  RIVA_DLMS_PLC_DISABLED: 'RIVA_DLMS_PLC_DISABLED',
  RIVA_DLMS_RF_DISABLED: 'RIVA_DLMS_RF_DISABLED',
  RIVA_DLMS_SYSTEM_DATABASE_ERROR: 'RIVA_DLMS_SYSTEM_DATABASE_ERROR',
  RIVA_DLMS_TERMINAL_COVER_TAMPER: 'RIVA_DLMS_TERMINAL_COVER_TAMPER',
  RIVA_DLMS_MAGNETIC_TAMPER_DETECTED: 'RIVA_DLMS_MAGNETIC_TAMPER_DETECTED',
  RIVA_DLMS_METER_INVERSION_DETECTED: 'RIVA_DLMS_METER_INVERSION_DETECTED',
  RIVA_DLMS_AUTOMATIC_DISCONNECT: 'RIVA_DLMS_AUTOMATIC_DISCONNECT',
  RIVA_DLMS_AUTOMATIC_RECONNECT: 'RIVA_DLMS_AUTOMATIC_RECONNECT',
  RIVA_DLMS_BASE_COMMUNICATION_ERROR: 'RIVA_DLMS_BASE_COMMUNICATION_ERROR',
  RIVA_DLMS_BILLING_DATA_CLEARED: 'RIVA_DLMS_BILLING_DATA_CLEARED',
  RIVA_DLMS_REVERSE_ENERGY_DETECTED: 'RIVA_DLMS_REVERSE_ENERGY_DETECTED',
  RIVA_DLMS_FORCE_SAVE_REQUEST_FAILED: 'RIVA_DLMS_FORCE_SAVE_REQUEST_FAILED',
  RIVA_DLMS_BLURT_LOSS_EXCEEDED_TIMEOUT: 'RIVA_DLMS_BLURT_LOSS_EXCEEDED_TIMEOUT',
  RIVA_DLMS_DEMAND_RESET_OCCURRED: 'RIVA_DLMS_DEMAND_RESET_OCCURRED',
  RIVA_DLMS_SERVICE_TYPE_OVERRIDE: 'RIVA_DLMS_SERVICE_TYPE_OVERRIDE',
  RIVA_DLMS_TEMPERATURE_THRESHOLD_EXCEEDED: 'RIVA_DLMS_TEMPERATURE_THRESHOLD_EXCEEDED',
  RIVA_DLMS_SERVICE_LIMITING_SETTING_CHANGED: 'RIVA_DLMS_SERVICE_LIMITING_SETTING_CHANGED',
  RIVA_DLMS_SERVICE_LIMITING_THRESHOLD_EXCEEDED: 'RIVA_DLMS_SERVICE_LIMITING_THRESHOLD_EXCEEDED',
  RIVA_DLMS_SERVICE_LIMITING_RESTORED: 'RIVA_DLMS_SERVICE_LIMITING_RESTORED',
  RIVA_DLMS_SERVICE_LIMITING_MODE_CHANGE_RECEIVED: 'RIVA_DLMS_SERVICE_LIMITING_MODE_CHANGE_RECEIVED',
  RIVA_DLMS_SERVICE_LIMITING_MODE_CHANGE_ACTIVATED: 'RIVA_DLMS_SERVICE_LIMITING_MODE_CHANGE_ACTIVATED',
  RIVA_DLMS_ARC_DETECTION_TRIGGERED: 'RIVA_DLMS_ARC_DETECTION_TRIGGERED',
  RIVA_DLMS_SEASON_CHANGE: 'RIVA_DLMS_SEASON_CHANGE',
  RIVA_DLMS_LAST_CALENDAR_SEASON_CHANGE: 'RIVA_DLMS_LAST_CALENDAR_SEASON_CHANGE',
  RIVA_DLMS_SELF_READ_DATA_FAILURE: 'RIVA_DLMS_SELF_READ_DATA_FAILURE',
  RIVA_DLMS_END_OF_BILLING_PERIOD: 'RIVA_DLMS_END_OF_BILLING_PERIOD',
  RIVA_DLMS_CONTAINER_START_FAILURE: 'RIVA_DLMS_CONTAINER_START_FAILURE',
  RIVA_DLMS_CONTAINER_STOPPED_UNEXPECTEDLY: 'RIVA_DLMS_CONTAINER_STOPPED_UNEXPECTEDLY',
  RIVA_DLMS_TOU_CONFIGURATION_UPDATED: 'RIVA_DLMS_TOU_CONFIGURATION_UPDATED',
  RIVA_DLMS_TOU_CONFIGURATION_FAILED: 'RIVA_DLMS_TOU_CONFIGURATION_FAILED',
  RIVA_DLMS_MAX_DISCONNECT_LOCKOUT_OCCURRED: 'RIVA_DLMS_MAX_DISCONNECT_LOCKOUT_OCCURRED',
  METER_DATA_WITH_FUTURE_TIME: 'METER_DATA_WITH_FUTURE_TIME',
  EVENT_WITH_FUTURE_TIME: 'EVENT_WITH_FUTURE_TIME',
  METER_DATA_TIME_DUPLICATED: 'METER_DATA_TIME_DUPLICATED',
  NIC_CLOCK_ERROR: 'NIC_CLOCK_ERROR',
  NIC_FIRST_TIME_SET_AFTER_BOOT: 'NIC_FIRST_TIME_SET_AFTER_BOOT',
  NIC_POWER_DOWN: 'NIC_POWER_DOWN',
  TRAP_SPAMMER_IDENTIFIED: 'TRAP_SPAMMER_IDENTIFIED',
  NIC_EL_SEC_OPEN: 'NIC_EL_SEC_OPEN',
  NIC_EL_MLME_LG_REG_FAILURE: 'NIC_EL_MLME_LG_REG_FAILURE',
  NIC_EL_METER_SEC_BCMAC_FAIL: 'NIC_EL_METER_SEC_BCMAC_FAIL',
  EVENT_PWR_FAIL_DETECT_DISABLE: 'EVENT_PWR_FAIL_DETECT_DISABLE',
  NIC_SECURITY_AUTH_FAILURE: 'NIC_SECURITY_AUTH_FAILURE',
  NIC_SECURITY_NO_PRIVILEGE: 'NIC_SECURITY_NO_PRIVILEGE',
  NIC_IMAGE_SECURITY_EVENT: 'NIC_IMAGE_SECURITY_EVENT',
  NIC_LL_HANDSHAKE_FAILED: 'NIC_LL_HANDSHAKE_FAILED',
  NIC_POWER_RESTORE: 'NIC_POWER_RESTORE',
  EL_EVENT_SEC_THRESHOLD_TRAP: 'EL_EVENT_SEC_THRESHOLD_TRAP',
  RIVA_DLMS_POWER_OUTAGE_DETECTED: 'RIVA_DLMS_POWER_OUTAGE_DETECTED',
  RIVA_DLMS_WIFI_CONNECTION_RELEASED: 'RIVA_DLMS_WIFI_CONNECTION_RELEASED',
  RIVA_DLMS_VERY_SHORT_TIME_THD_V_EVENT_PHASE_C: 'RIVA_DLMS_VERY_SHORT_TIME_THD_V_EVENT_PHASE_C',
  NIC_MOMENTARY_PWR_DISRUPTION: 'NIC_MOMENTARY_PWR_DISRUPTION',
  SUCCESSFUL_COMM_ALARM_CLEAR: 'SUCCESSFUL_COMM_ALARM_CLEAR',
  RIVA_DLMS_LONG_INTERVAL_ELECTRIC: 'RIVA_DLMS_LONG_INTERVAL_ELECTRIC',
  RIVA_DLMS_SHORT_INTERVAL_ELECTRIC: 'RIVA_DLMS_SHORT_INTERVAL_ELECTRIC',
  RIVA_DLMS_TEST_MODE_ELECTRIC: 'RIVA_DLMS_TEST_MODE_ELECTRIC',
  RIVA_DLMS_TIME_CHANGE_FORWARD: 'RIVA_DLMS_TIME_CHANGE_FORWARD',
  RIVA_DLMS_TIME_CHANGE_BACKWARD: 'RIVA_DLMS_TIME_CHANGE_BACKWARD',
  NIC_TIME_SET: 'NIC_TIME_SET',
  NIC_TIME_CHANGE: 'NIC_TIME_CHANGE',
  NIC_NVRAM_CRITICAL_ERROR: 'NIC_NVRAM_CRITICAL_ERROR',
};
