import React from 'react';
import Box from '@mui/material/Box';
import { useFeatureToggle, FEATURE_TOGGLES } from 'sharedComponents/FeatureToggle';

const Scene = ({ className = undefined, noMargin = false, noBar = false, noTopBar, noMenu, ...props }) => {
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  const sxMargins = [noMargin ? { ml: 0 } : { mt: 1, mx: 2, mb: 0 }, noMenu && { ml: 0 }];
  return (
    <Box
      sx={[
        noBar
          ? {
              height: geThemeFeature ? 'calc(100vh - 104px)' : noTopBar ? '100vh' : 'calc(100vh - 64px)',
              overflowY: 'auto',
            }
          : { height: geThemeFeature ? 'calc(100vh - 168px)' : 'calc(100vh - 64px)', overflowY: 'auto' },
      ]}
    >
      <Box
        className={className}
        sx={[
          {
            overflowX: 'hidden',
          },
        ].concat(geThemeFeature ? {} : sxMargins)}
        {...props}
      />
    </Box>
  );
};

export default Scene;
