import errorCodes from '../../util/errorCodes';
import { creators as authCreators } from '../ducks/authDuck';
import { loginWithActiveDirectory } from '../api/loginApi';
import { tokenDuck } from '../../store/jwtMiddleware';
import { initialize } from './init';
import { login } from '../api/loginApi';

export const loginAction = (username, password) => async dispatch => {
  dispatch(authCreators.loginStart());

  try {
    const { token, refreshToken, expires } = await login(username, password);
    if (!(token && refreshToken && expires)) {
      throw new Error(errorCodes.HTTP_RESPONSE_STATUS_UNAUTHORIZED);
    }
    dispatch(tokenDuck.creators.setToken(token, refreshToken, expires));
    dispatch(initialize());
    dispatch(authCreators.loginSuccess());
  } catch (error) {
    dispatch(authCreators.loginError(error));
  }
};

export const activeDirectoryAction = adToken => async dispatch => {
  dispatch(authCreators.loginStart());
  try {
    const { token, refreshToken, expires } = await loginWithActiveDirectory(adToken);
    if (!(token && refreshToken && expires)) {
      throw new Error(errorCodes.HTTP_RESPONSE_STATUS_UNAUTHORIZED);
    }
    dispatch(tokenDuck.creators.setToken(token, refreshToken, expires));
    dispatch(initialize());
    dispatch(authCreators.loginSuccess());
  } catch (error) {
    dispatch(authCreators.loginError(error));
  }
};

export const logoutAction = () => dispatch => {
  dispatch(authCreators.logout());
  dispatch(tokenDuck.creators.clearToken());
};
