import { connect } from 'react-redux';
import TasksFilterBarButton from './TasksFilterBarButton';
import { creators, selectors } from '../../ducks/tasksDuck';

const mapStateToProps = state => ({
  hasNewTasks: selectors.hasNewTasks(state),
});

const mapDispatchToProps = { setShowTasksList: creators.setShowTasksList };

export default connect(mapStateToProps, mapDispatchToProps)(TasksFilterBarButton);
