import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTaskComments, addTaskComment } from '../../../../api/taskManagerApi';
import { selectors as authSelectors } from 'App/ducks/authDuck';

const useTaskComments = (organizationShortName, taskId) => {
  const [taskComments, setTaskComments] = useState([]);
  const token = useSelector(state => authSelectors.token(state));

  const fetchComments = useCallback(async () => {
    if (organizationShortName && taskId) {
      const data = await getTaskComments(token, organizationShortName, taskId, 0, 100);
      const sortedData = data.entries.slice(0);
      sortedData.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setTaskComments(sortedData);
    }
  }, [organizationShortName, taskId, token]);

  const addComment = useCallback(
    async comment => {
      if (organizationShortName && taskId && comment) {
        await addTaskComment(token, organizationShortName, taskId, { content: comment });
        fetchComments();
      }
    },
    [organizationShortName, taskId, token, fetchComments]
  );

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);
  return [taskComments, addComment];
};

export default useTaskComments;
