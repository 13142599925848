import React, { useState, useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GroupIcon from '@mui/icons-material/Domain';
import { FormattedMessage } from 'react-intl';

const Footer = ({ user, setActiveRole, activeRole }) => {
  const [selectOrgAnchor, setSelectOrgAnchor] = useState(null);

  const closeSelectOrg = useCallback(() => {
    setSelectOrgAnchor(null);
  }, [setSelectOrgAnchor]);
  const openSelectOrg = useCallback(
    e => {
      setSelectOrgAnchor(e.currentTarget);
    },
    [setSelectOrgAnchor]
  );
  const setActiveRoleCallback = useCallback(
    role => {
      setActiveRole(role);
      closeSelectOrg();
    },
    [setActiveRole, closeSelectOrg]
  );
  return (
    <AppBar
      component="div"
      position="fixed"
      sx={{
        p: 0,
        borderTop: '1px solid',
        borderColor: 'borders.moderate',
        height: 40,
        top: 'auto',
        left: 0,
        bottom: 0,
        bgcolor: 'surface.7',
        zIndex: 2000,
      }}
    >
      <Box sx={{ height: 40, display: 'flex', alignItems: 'center', ml: 2 }}>
        <GroupIcon />
        <Typography sx={{ ml: 1 }}>
          <FormattedMessage id="sharedComponents-Footer-organization" defaultMessage="Organization:" />
        </Typography>
        {user && user.roles && (
          <>
            <Menu anchorEl={selectOrgAnchor} open={!!selectOrgAnchor} onClose={closeSelectOrg}>
              {user.roles.map(item =>
                item.organizationDetails ? (
                  <MenuItem key={item.organizationId} onClick={() => setActiveRoleCallback(item)}>
                    {item.organizationDetails.name}
                  </MenuItem>
                ) : null
              )}
            </Menu>
            <Button
              color="inherit"
              disabled={user.roles.length === 1}
              onClick={openSelectOrg}
              sx={{ py: 1, px: '13px', overflow: 'hidden', ml: 1 }}
            >
              {activeRole && activeRole.organizationDetails && activeRole.organizationDetails.name}
            </Button>
          </>
        )}
      </Box>
    </AppBar>
  );
};

export default Footer;
