import { post, get } from 'util/fetch';
import { jwtDecode } from 'jwt-decode';
import toFormEncoding from 'form-urlencoded';
import { login as loginApiUrl } from '../../config/backendRoutes';

const loginUrl = `${loginApiUrl}/authentication/token`;
const activeDirectoryEndpoint = `${loginApiUrl}/authentication/activedirectory`;
const userDetailsEndpoint = `${loginApiUrl}/users`;
const organizationDetailsEndpointUrl = `${loginApiUrl}/organizations`;

const resetPasswordEndpoint = `${loginApiUrl}/password/set`;
const sendResetPasswordMailEndpoint = `${loginApiUrl}/password/reset`;
const isResetPasswordTokenValidEndpoint = token => `${sendResetPasswordMailEndpoint}/${token}`;

const expiresToDateString = expires => new Date(new Date().getTime() + expires * 1000).toString();

const fetchToken = async (url, body) => {
  const { access_token, refresh_token, expires_in } = await post(url, {
    contentType: 'application/x-www-form-urlencoded',
    body: toFormEncoding(body),
    stringifyBody: false,
  });

  return {
    token: access_token,
    refreshToken: refresh_token,
    expires: expiresToDateString(expires_in),
  };
};

export const login = (username, password) => {
  return fetchToken(loginUrl, {
    grant_type: 'password',
    scope: 'metercloud-identity',
    username,
    password,
  });
};

export const refreshToken = ({ refreshToken }) =>
  fetchToken(loginUrl, { grant_type: 'refresh_token', scope: 'metercloud-identity', refresh_token: refreshToken });

export const fetchLoggedInUserDetails = async ({ token }) => {
  const { userId } = jwtDecode(token);
  return get(`${userDetailsEndpoint}/${userId}`, { token });
};

export const fetchLoggedInUserOrganizationDetails = async ({ token }) =>
  get(`${organizationDetailsEndpointUrl}/${jwtDecode(token).organizationId}`, { token });

export const resetPassword = (setPasswordToken, requestedPassword, repeatedPassword) =>
  post(resetPasswordEndpoint, { body: { setPasswordToken, newPassword: { requestedPassword, repeatedPassword } } });

export const loginWithActiveDirectory = async token => {
  const { access_token, refresh_token, expires_in } = await get(activeDirectoryEndpoint, {
    token,
    contentType: 'application/x-www-form-urlencoded',
  });
  return {
    token: access_token,
    refreshToken: refresh_token,
    expires: expiresToDateString(expires_in),
  };
};

export const sendResetPasswordMail = email => post(sendResetPasswordMailEndpoint, { body: { email } });

export const isPasswordResetTokenValid = token => get(isResetPasswordTokenValidEndpoint(token), { parseResult: false });
