import React from 'react';
import { defineMessage } from 'react-intl';
import DocumentationIcon from 'utilihive-icons/Documentation';
import settings from 'Settings/routes/routes';
import heartbeat from 'Heartbeat/routes/routes';
import datalake from 'Datalake/routes/routes';
import platform from 'Platform/routes/routes';
import assets from 'Assets/routes/routes';
import ghostwriter from 'Ghostwriter/routes/routes';
import smoc from 'SMOC/routes/routes';
import security from 'Security/routes/routes';

export const Documentation = {
  path: 'https://docs.utilihive.io',
  intlMessage: defineMessage({ id: 'route.documentation', defaultMessage: 'Documentation' }),
  Icon: <DocumentationIcon />,
};

const routes = [heartbeat, datalake, smoc, assets, ghostwriter, settings, platform, security];

export const navRoutes = routes.map(({ path, intlMessage, Icon, moduleNameForOrganisationAccess }) => ({
  path,
  intlMessage,
  Icon,
  moduleNameForOrganisationAccess,
}));

export default routes;
