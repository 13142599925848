import { get, put } from 'util/fetch';
import { configuration } from '../../config/backendRoutes';

export const fetchConfiguration = async (token, orgShortName, userId) =>
  get(`${configuration}/v1/${orgShortName}/configuration/${userId}`, { token });

export const putOrganizationConfiguration = async (token, orgShortName, body) =>
  put(`${configuration}/v1/${orgShortName}/configuration`, { token, body });

export const putUserConfiguration = async (token, orgShortName, userId, body) =>
  put(`${configuration}/v1/${orgShortName}/configuration/${userId}`, { token, body });
