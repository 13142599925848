import '@fontsource/roboto/latin.css';
import { createTheme } from '@mui/material';
import { typography } from './typography';
import componentStyles from './components';

const theme = palette => {
  const components = componentStyles(palette);
  return createTheme({
    components,
    palette,
    typography,
  });
};

export default theme;
