import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MessageIcon from '@mui/icons-material/Message';
import { useDateFormatter } from 'sharedComponents/DateFormatter';

import useTaskComments from './useTaskComments';

const TaskDetail = ({ organizationShortName, taskId, users }) => {
  const intl = useIntl();
  const [newComment, setNewComment] = useState('');
  const [comments, addComment] = useTaskComments(organizationShortName, taskId);
  const dateFormatter = useDateFormatter();
  const onEnter = useCallback(
    e => {
      if (e.keyCode === 13 && newComment) {
        addComment(newComment);
        setNewComment('');
      }
    },
    [addComment, newComment, setNewComment]
  );
  return (
    <Box sx={{ m: 2, mt: 3 }}>
      <Typography variant="subtitle1">
        <FormattedMessage id="Tasks-TaskDetails-details-comments-title" />
      </Typography>
      <Box sx={{ m: 1 }}>
        <TextField
          error={false}
          value={newComment}
          onKeyDown={onEnter}
          onChange={e => setNewComment(e.target.value)}
          fullWidth
          label={intl.formatMessage({ id: 'Tasks-TaskDetails-details-comments-leaveComment' })}
        />
      </Box>
      <List>
        {comments.map(comment => (
          <ListItem divider key={comment.id}>
            <ListItemIcon>
              <MessageIcon />
            </ListItemIcon>
            <ListItemText
              primary={comment.content}
              secondary={intl.formatMessage(
                { id: 'Tasks-TaskDetails-details-comments-secondary' },
                {
                  created: dateFormatter.dateTime(comment.createdAt),
                  user: users.find(user => `${user.userId}` === comment.createdBy).name,
                }
              )}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TaskDetail;
