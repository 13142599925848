import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import BackIcon from '@mui/icons-material/ArrowBack';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import TasksList from './TasksList';
import TaskDetails from './TaskDetails';

const Tasks = ({
  tasks,
  user,
  organizationShortName,
  fetchTasks,
  fetchTask,
  setShowTasksList,
  showTasksList,
  showTaskId,
  setShowTaskId,
  fetchUsers,
  users,
}) => {
  const showTasks = useFeatureToggle(FEATURE_TOGGLES.TASKS);

  useEffect(() => {
    if (organizationShortName && user.userId && showTasks && showTasksList) {
      fetchTasks(organizationShortName, user.userId);
      fetchUsers(organizationShortName);
    }
  }, [organizationShortName, user.userId, fetchTasks, fetchUsers, showTaskId, showTasksList, showTasks]);
  const onClose = () => {
    setShowTasksList(false);
  };
  return (
    <div>
      <Drawer anchor="right" open={showTasksList} onClose={onClose}>
        <Box sx={showTaskId ? { width: 450 } : { width: 350, overflowX: 'hidden' }}>
          {showTaskId && (
            <Button sx={{ ml: 1, mt: 1 }} color="inherit" onClick={() => setShowTaskId(null)}>
              <BackIcon />
            </Button>
          )}
          {!showTaskId && <TasksList users={users} selectTask={setShowTaskId} tasks={tasks} />}
          {showTaskId && (
            <TaskDetails
              users={users}
              fetchTask={fetchTask}
              taskId={showTaskId}
              organizationShortName={organizationShortName}
              setShowTaskId={setShowTaskId}
            />
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default Tasks;
