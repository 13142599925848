import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { deleteTask, getTask, updateTask } from '../../../api/taskManagerApi';
import { selectors as authSelectors } from 'App/ducks/authDuck';

const useTaskDetails = (organizationShortName, taskId) => {
  const [task, setTask] = useState();
  const token = useSelector(state => authSelectors.token(state));
  const fetchTaskCallback = useCallback(async () => {
    if (organizationShortName && taskId) {
      const data = await getTask(token, organizationShortName, taskId);
      setTask(data);
    }
  }, [organizationShortName, taskId, token]);
  const updateTaskCallback = useCallback(
    async newTask => {
      await updateTask(token, organizationShortName, taskId, { ...task, ...newTask });
      await fetchTaskCallback();
    },
    [token, organizationShortName, taskId, task, fetchTaskCallback]
  );
  const deleteTaskCallback = useCallback(
    async newTask => {
      await deleteTask(token, organizationShortName, taskId);
    },
    [token, organizationShortName, taskId]
  );
  useEffect(() => {
    fetchTaskCallback();
  }, [fetchTaskCallback]);
  return [task, updateTaskCallback, deleteTaskCallback];
};

export default useTaskDetails;
