import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDateContext } from '../FilterBar/FancyDateSelect/utils/DateContext';
import DateRangePickerPopover from './DateRangePickerPopover';
import { useIntl } from 'react-intl';
import { setTimeHistory } from '../../App/api/localStorage';

export const DATE_RANGE_PICKER_OPTIONS = intl => [
  {
    label: intl.formatMessage({ id: 'DateRangePicker-option-today', defaultMessage: 'Today' }),
    getDate: () => ({
      fromDate: moment().startOf('day'),
      toDate: moment(),
      dateOptionIndex: 0,
    }),
  },
  {
    label: intl.formatMessage({ id: 'DateRangePicker-option-yesterday', defaultMessage: 'Yesterday' }),
    getDate: () => ({
      fromDate: moment().subtract(1, 'day'),
      toDate: moment(),
      dateOptionIndex: 1,
    }),
  },
  {
    label: intl.formatMessage({ id: 'DateRangePicker-option-lastWeek', defaultMessage: 'Last Week' }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(7, 'days'),
      toDate: moment(),
      dateOptionIndex: 2,
    }),
  },
  {
    label: intl.formatMessage({ id: 'DateRangePicker-option-lastMonth', defaultMessage: 'Last Month' }),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(30, 'days'),
      toDate: moment(),
      dateOptionIndex: 3,
    }),
  },
  {
    label: intl.formatMessage(
      {
        id: 'DateRangePicker-option-lastMonths',
        defaultMessage: 'Last {months} Months',
      },
      { months: 3 }
    ),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(90, 'days'),
      toDate: moment(),
      dateOptionIndex: 4,
    }),
  },
  {
    label: intl.formatMessage(
      {
        id: 'DateRangePicker-option-lastMonths',
        defaultMessage: 'Last {months} Months',
      },
      { months: 6 }
    ),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(180, 'days'),
      toDate: moment(),
      dateOptionIndex: 5,
    }),
  },
  {
    label: intl.formatMessage(
      {
        id: 'DateRangePicker-option-lastMonths',
        defaultMessage: 'Last {months} Months',
      },
      { months: 12 }
    ),
    getDate: () => ({
      fromDate: moment()
        .startOf('day')
        .subtract(1, 'years'),
      toDate: moment(),
      dateOptionIndex: 6,
    }),
  },
];

const DateRangePickerPopoverWrapper = () => {
  const { dates, updateDates } = useDateContext();
  const intl = useIntl();
  const formatDate = useCallback(date => intl.formatDate(date), [intl]);
  const quickSelectOptions = useMemo(() => DATE_RANGE_PICKER_OPTIONS(intl), [intl]);
  const labels = useMemo(
    () => ({
      setDatesButton: intl.formatMessage({ id: 'DateRangePicker-button-setDates', defaultMessage: 'Set Dates' }),
      cancelButton: intl.formatMessage({ id: 'DateRangePicker-button-cancel', defaultMessage: 'Cancel' }),
      errorIsAfterOther: intl.formatMessage({
        id: 'validation.form.field.date.is.after.other',
        defaultMessage: 'Start date cannot be after end date',
      }),
      errorIsBeforeOther: intl.formatMessage({
        id: 'validation.form.field.date.is.before.other',
        defaultMessage: 'End date cannot be before start date',
      }),
      quickDatesTitle: intl.formatMessage({ id: 'DateRangePicker-QuickDates-title', defaultMessage: 'Quick dates' }),
      endingDate: intl.formatMessage({ id: 'DateRangePicker-endingDate-label', defaultMessage: 'Ending Date' }),
      endingTime: intl.formatMessage({ id: 'DateRangePicker-endingTime-label', defaultMessage: 'Ending Time' }),
      startingDate: intl.formatMessage({ id: 'DateRangePicker-startingDate-label', defaultMessage: 'Starting Date' }),
      startingTime: intl.formatMessage({ id: 'DateRangePicker-startingTime-label', defaultMessage: 'Starting Time' }),
    }),
    [intl]
  );
  const updateDatesCallback = useCallback(
    dates => {
      updateDates(dates);
      setTimeHistory(dates);
    },
    [updateDates]
  );
  return (
    <DateRangePickerPopover
      formatDate={formatDate}
      value={dates}
      defaultValue={{ fromDate: moment().subtract(1, 'hours'), toDate: moment() }}
      onChange={updateDatesCallback}
      labels={labels}
      quickSelectOptions={quickSelectOptions}
    />
  );
};

export default DateRangePickerPopoverWrapper;
