import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import MoreActionsIcon from '@mui/icons-material/MoreVert';
import UserListMenu from './UserListMenu';
import Labels from './Labels';
import DateFormatter from 'sharedComponents/DateFormatter';
import TaskComments from './TaskComments';
import TaskActions from './TaskActions';
import TaskResources from './TaskResources';
import TaskTitle from './TaskTitle';
import TaskDescription from './TaskDescription';
import TaskStatus from './TaskStatus';
import useTaskDetails from './useTaskDetails';

const TaskDetail = ({ organizationShortName, fetchTask, taskId, users, setShowTaskId }) => {
  const [showMenu, setShowMenu] = useState(null);
  const usersOptions = useMemo(() => users.map(user => ({ value: `${user.userId}`, label: user.name })), [users]);

  const [task, updateTask, deleteTask] = useTaskDetails(organizationShortName, taskId, fetchTask);

  const onDelete = useCallback(async () => {
    await deleteTask();
    setShowMenu(null);
    setShowTaskId(null);
  }, [deleteTask, setShowTaskId, setShowMenu]);

  if (!task) {
    return null;
  }

  return (
    <div role="presentation">
      <Box sx={{ position: 'absolute', top: 1, right: 1 }}>
        <Button color="inherit" onClick={e => setShowMenu(e.target)}>
          <MoreActionsIcon />
        </Button>
        <Menu id="task-menu" anchorEl={showMenu} open={!!showMenu} onClose={() => setShowMenu(null)}>
          <MenuItem onClick={onDelete}>
            <FormattedMessage id="DeleteButton-label-delete" defaultMessage="Delete" />
          </MenuItem>
        </Menu>
      </Box>
      <TaskTitle title={task.title} onChange={val => updateTask({ title: val })} />
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-owner" />
            </TableCell>
            <TableCell>
              <UserListMenu disabled value={task.createdBy} options={usersOptions} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-assignee" />
            </TableCell>
            <TableCell>
              <UserListMenu
                value={task.assignedUser}
                options={usersOptions}
                onChange={val => updateTask({ assignedUser: val })}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-status" />
            </TableCell>
            <TableCell>
              <TaskStatus />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-label" />
            </TableCell>
            <TableCell>
              <Labels labels={task.labels} onChange={val => updateTask({ labels: val })} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-created" />
            </TableCell>
            <TableCell>
              <DateFormatter date={task.createdAt} variant="dateTime" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <FormattedMessage id="Tasks-TaskDetails-details-updated" />
            </TableCell>
            <TableCell>
              <DateFormatter date={task.lastUpdatedAt || task.createdAt} variant="dateTime" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <TaskDescription description={task.description} onChange={val => updateTask({ description: val })} />
      <TaskActions actions={task.actions} />
      <TaskResources resources={task.resources} />
      <TaskComments organizationShortName={organizationShortName} taskId={taskId} users={users} />
    </div>
  );
};

export default TaskDetail;
