import { FOUNDATION } from 'config/features';

export const USER_ROLES = {
  ADMIN: 'Admin',
  OPERATOR: 'Operator',
  MONITOR: 'Monitor',
  BILLING: 'Billing',
  DEPLOYER: 'Deployer',
  AGENT: 'Agent',
};

export const OWNER_ROLE = 'Owner';

export const FOUNDATION_OWNER_ORG = 'foundation';

export const USER_ROLES_LABELS = [
  { intlId: 'UserConfig-option-admin', value: USER_ROLES.ADMIN },
  { intlId: 'UserConfig-option-operator', value: USER_ROLES.OPERATOR },
  { intlId: 'UserConfig-option-monitor', value: USER_ROLES.MONITOR },
  { intlId: 'UserConfig-option-billing', value: USER_ROLES.BILLING },
  { intlId: 'UserConfig-option-deployer', value: USER_ROLES.DEPLOYER },
  { intlId: 'UserConfig-option-agent', value: USER_ROLES.AGENT },
];

export const isUserOwner = roles => {
  if (FOUNDATION) {
    return (
      roles &&
      roles.some(item => item.organizationDetails.name === FOUNDATION_OWNER_ORG && item.role === USER_ROLES.ADMIN)
    );
  }
  return (
    roles &&
    roles.some(item => (item.organizationId === 1 || item.organizationId === '1') && item.role === USER_ROLES.ADMIN)
  );
};

export const isUserAdmin = user => organizationId =>
  user &&
  user.roles &&
  user.roles.some(item => item.organizationId === organizationId && item.role === USER_ROLES.ADMIN);
