import { connect } from 'react-redux';
import { selectors as userSelectors } from '../../ducks/userDuck';
import { selectors as configurationSelectors } from '../../ducks/configurationDuck';
import Navigation from './NavigationBar';
import { featuresAction as fetchFeatures } from '../../actions/features';
import { logoutAction } from 'App/actions/auth';

const mapStateToProps = state => ({
  modules: configurationSelectors.modules(state) || [],
  userRoles: userSelectors.roles(state),
  organizationShortName: userSelectors.organizationShortName(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
  fetchFeatures,
};

const NavigationContainer = connect(mapStateToProps, mapDispatchToProps)(Navigation);

export default NavigationContainer;
