import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssignmentIcon from '@mui/icons-material/Assignment';

const TasksListItem = ({ task, selectTask, users }) => {
  const user = useMemo(
    () =>
      users.find(user => task.assignedUser === `${user.userId}`) ||
      users.find(user => task.createdBy === `${user.userId}`),
    [users, task]
  );
  return (
    <ListItem button onClick={() => selectTask(task.id)} divider key={task.id}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText
        primary={task.title}
        secondary={
          <FormattedMessage id="Tasks-TasksList-ListItem-secondary" values={{ user: user ? user.name : 'Unknown' }} />
        }
      />
    </ListItem>
  );
};

export default TasksListItem;
