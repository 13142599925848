import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import TaskResourceItem from './TaskResourceItem';
import { fetchIssueDtoListAction } from 'SMOC/actions/issueDto';

const TaskResources = ({ resources = [] }) => {
  const dispatch = useDispatch();
  const [issues, setIssues] = useState([]);
  const mappedIssues = useMemo(
    () =>
      resources.map(resource => {
        const fields = resource.resource.split(':');
        return {
          urn: resource,
          source: fields[2],
          mrId: fields[4],
        };
      }),
    [resources]
  );
  const getResources = useCallback(async () => {
    if (mappedIssues.length > 0) {
      const result = await dispatch(
        fetchIssueDtoListAction(
          mappedIssues.map(item => item.mrId),
          mappedIssues[0].source
        )
      );
      setIssues(result);
    }
  }, [dispatch, mappedIssues]);
  useEffect(() => {
    getResources();
  }, [getResources]);
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="subtitle1">
        <FormattedMessage id="Tasks-TaskDetails-details-resources-title" />
      </Typography>
      <List>
        {issues.map(issue => (
          <TaskResourceItem key={issue.mrId} issue={issue} />
        ))}
      </List>
    </Box>
  );
};

export default TaskResources;
