import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const TaskTitle = ({ title, onChange }) => {
  const [titleState, setTitle] = useState(title);
  return (
    <Box sx={{ textAlign: 'center', mb: 3 }}>
      <TextField
        onBlur={e => onChange(e.target.value)}
        value={titleState}
        onChange={e => setTitle(e.target.value)}
        variant="standard"
        inputProps={{ sx: { textAlign: 'center' } }}
      />
    </Box>
  );
};

export default TaskTitle;
