import moment from 'moment';

export const EVENT_SCENARIOS_OPTION_NONE = 'None';

export const SIMULATION_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  RUNNABLE: 'RUNNABLE',
  RUNNING: 'RUNNING',
  STARTING: 'STARTING',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  FAILING: 'FAILING',
  FAILED: 'FAILED',
  ABORTING_SLA_EXCEEDED: 'ABORTING_SLA_EXCEEDED',
  SAVING: 'SAVING',
  FINISHED: 'FINISHED',
  PLANNED: 'PLANNED',
};

export const hasTransitionalStatus = simulation =>
  simulation &&
  (simulation.state === SIMULATION_STATE.RUNNING ||
    simulation.state === SIMULATION_STATE.CANCELLING ||
    simulation.state === SIMULATION_STATE.CANCELLED ||
    simulation.state === SIMULATION_STATE.STARTING ||
    simulation.state === SIMULATION_STATE.SAVING);

export const isSimulationRunning = simulation =>
  simulation.state === SIMULATION_STATE.RUNNING ||
  simulation.state === SIMULATION_STATE.CANCELLING ||
  simulation.state === SIMULATION_STATE.CANCELLED ||
  simulation.state === SIMULATION_STATE.STARTING ||
  simulation.state === SIMULATION_STATE.FAILING ||
  simulation.state === SIMULATION_STATE.FAILED ||
  simulation.state === SIMULATION_STATE.SAVING;

export const hasSimulationFailed = simulation =>
  simulation.state === SIMULATION_STATE.FAILING ||
  simulation.state === SIMULATION_STATE.FAILED ||
  simulation.state === SIMULATION_STATE.ABORTING_SLA_EXCEEDED;

export const hasSimulationCancelled = simulation =>
  simulation.state === SIMULATION_STATE.CANCELLING || simulation.state === SIMULATION_STATE.CANCELLED;

export const isCancelable = simulation =>
  isSimulationRunning(simulation) && simulation.state !== SIMULATION_STATE.SAVING;

export const isSimulationRunnable = simulation =>
  simulation.state === SIMULATION_STATE.NOT_STARTED || simulation.state === SIMULATION_STATE.RUNNABLE;

export const sortSimulationOldestToNewest = (simulations = []) => {
  const sortedSims = simulations.slice(0);
  sortedSims.sort((simA, simB) => simB.id - simA.id);
  return sortedSims;
};

export const findLatestSimulation = (simulations = []) => {
  return sortSimulationOldestToNewest(simulations)[0];
};

export const findFirstRunnableSimulation = (simulations = []) => {
  return simulations
    .sort((simA, simB) => new Date(simB.simulationStartDateTime) - new Date(simA.simulationStartDateTime))
    .find(simulation => simulation.state === SIMULATION_STATE.RUNNABLE || hasTransitionalStatus(simulation));
};

export const getLatestItemByDate = items => {
  const sortedItems =
    items &&
    items.length &&
    items.sort((a, b) => new Date(b.simulationEndDateTime) - new Date(a.simulationEndDateTime));
  return sortedItems ? sortedItems[0] : {};
};

export const isSimulationValid = simulation => {
  return (
    simulation &&
    typeof simulation.name === 'string' &&
    simulation.name.length > 0 &&
    typeof simulation.usageScenarioId === 'number' &&
    typeof simulation.eventScenarioId === 'number' &&
    moment.isMoment(simulation.startDate) &&
    moment.isMoment(simulation.endDate)
  );
};
