import React, { useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Close';
import ConfirmIcon from '@mui/icons-material/Done';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

const Labels = ({ labels, onChange }) => {
  const handleDelete = useCallback(
    deletedLabel => {
      onChange(labels.filter(label => label !== deletedLabel));
    },
    [labels, onChange]
  );
  const [showAddLabelText, setShowAddLabelText] = useState(false);
  const [addLabelText, setAddLabelText] = useState('');

  const onClose = useCallback(() => {
    setShowAddLabelText(false);
    setAddLabelText('');
  }, [setShowAddLabelText, setAddLabelText]);
  const handleAdd = useCallback(() => {
    onChange(labels.concat(addLabelText));
    onClose();
  }, [labels, addLabelText, onClose, onChange]);
  return (
    <div>
      {labels &&
        !showAddLabelText &&
        labels.map(label => <Chip key={label} label={label} onDelete={() => handleDelete(label)} />)}
      {showAddLabelText ? (
        <>
          <TextField
            sx={{ width: '50%' }}
            variant="standard"
            autoFocus={true}
            value={addLabelText}
            onChange={e => setAddLabelText(e.target.value)}
          />
          <IconButton onClick={onClose}>
            <CancelIcon />
          </IconButton>
          <IconButton onClick={handleAdd}>
            <ConfirmIcon />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={() => setShowAddLabelText(true)}>
          <AddIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Labels;
